import { useEffect, useState } from "react"
import { useWindowDimensions } from "../../hooks/dimensions"
import { scaleElementToWidth } from "../../utils/utils"
import { SVGContainer } from "./svgLine.styles"

let row = [0, 1, 2 ,5, 4, 3 , 6, 7, 8, 9]
const mobileRows = [0,1,2,3,4,5,6,7,8,9]

let coordinates:{x:number, y: number}[] = []


const SVGLine = () => {


    const {width, height} = useWindowDimensions()
    const [size, setSize] = useState({
        width: 0,
        height: 0,
        top: 0
    })
    const [rows, setRows] = useState(width> 1000 ? row: mobileRows)
    
    useEffect(() => {
        console.log('WIDTH',width)
        if (width> 1000) {

            setRows(row)
        } else {
            setRows(mobileRows)
        }
    }, [width, height])

    useEffect(() => {
        setTimeout(() => {
            updateSVGPoints()
        }, 200)
        setTimeout(() => {
            updateSVGPoints()
        }, 500)

        setTimeout(() => {
            updateSVGPoints()
        }, 1200)
    }, [width, height])


    const updateSVGPoints = () => {
        coordinates=[]
        const newCoords:{x:number, y: number}[] = []
        const rows = width> 1000 ? row: mobileRows


        
        const top =  ((document.querySelector('#navigationBG')?.getBoundingClientRect().top) ?? 0) + window.scrollY


        const navigationBGHeight = document.querySelector('#navigationBG')?.getBoundingClientRect().height ?? 0
        const navigationBGWidth = document.querySelector('#navigationBG')?.getBoundingClientRect().width ?? 0
        const navigationBGLeft = document.querySelector('#navigationBG')?.getBoundingClientRect().left ?? 0





        const topOffset = top+navigationBGHeight*(width> 1000 ? 0.8: 0.5)


        const svgWidth = width




        const bottomOffset = (document.querySelector(`#aboutPage`)?.getBoundingClientRect().top ?? 0) + window.scrollY


        const svgheight = bottomOffset - topOffset + 100

     

        newCoords.push({
            x: navigationBGLeft + navigationBGWidth*0.2,
            y: navigationBGHeight*0.1,
        })

        rows.forEach((row) => {
            const top =   (((document.querySelector(`#mechanicsCircle${row}`)?.getBoundingClientRect().top) ?? 0) + window.scrollY) - topOffset
            const height =   ((document.querySelector(`#mechanicsCircle${row}`)?.getBoundingClientRect().height) ?? 0)  
            const width = ((document.querySelector(`#mechanicsCircle${row}`)?.getBoundingClientRect().width) ?? 0)
            const left = ((document.querySelector(`#mechanicsCircle${row}`)?.getBoundingClientRect().left) ?? 0)
            
            newCoords.push({
                x: left+width/2,
                y: top + height/2
            })
        
        })


        console.log('ROWS',rows)

        coordinates = newCoords

        setSize({
            width: svgWidth,
            height: svgheight,
            top: topOffset
        })

    }


    const buildPath = (coordinates: {x: number, y:number}[]) => {
        const offset = width> 1400 ? scaleElementToWidth(width, 160) : width> 1400 ? scaleElementToWidth(width, 80) :56
        const leftSide = width > 1000 ? (document.querySelector('#stats')?.getBoundingClientRect().left ?? 0)  - scaleElementToWidth(width, width> 1400 ? 85: 42) : 32
        const rightSide = width> 1000 ? (document.querySelector('#stats')?.getBoundingClientRect().left ?? 0) + (document.querySelector('#stats')?.getBoundingClientRect().width ?? 0) + scaleElementToWidth(width, 20) : width - 32
        let path = ``
        coordinates.forEach((coord, i) => {
            const {x, y} = coord
            if (width>1000) {
                if (i===0) {
                    path = path + `M ${x}, ${y}`
                } else {
                    const prevCoord = coordinates[i-1]
                    if (prevCoord.y !== coord.y) {
                        
                        if (coord.x < (window.innerWidth/2)) {
    
                            
                            path = path + ` L ${leftSide + offset},${prevCoord.y} Q${leftSide},${prevCoord.y} ${leftSide},${prevCoord.y + offset} L ${leftSide} ${coord.y-offset} Q ${leftSide} ${coord.y} ${leftSide + offset} ${coord.y} L ${coord.x} ${coord.y}`
                        } else {
                            path = path + ` L ${rightSide - offset},${prevCoord.y} Q${rightSide},${prevCoord.y} ${rightSide},${prevCoord.y + offset} L ${rightSide} ${coord.y-offset} Q ${rightSide} ${coord.y} ${rightSide - offset} ${coord.y} L ${coord.x} ${coord.y}`
                        }
                        
    
                        
                    } else {
                        path = path + ` L ${x} ${y}`
                    }
                    if (i === coordinates.length-1) {
                        path = path + ` L ${rightSide - offset} ${coord.y} Q ${rightSide} ${coord.y} ${rightSide} ${coord.y+offset}  L ${rightSide} ${size.height}`
                    }
                }
            } else {

                if (i===0) {
                    path = path + `M ${x}, ${y}`
                } else if (i === coordinates.length - 1) {
                    const prevCoord = coordinates[i-1]
                   
                    if (prevCoord.y !== coord.y) {
                  
                        if (coord.x < (window.innerWidth/2-1)) {
    
                            
                            path = path + ` L ${leftSide + offset},${prevCoord.y} Q${leftSide},${prevCoord.y} ${leftSide},${prevCoord.y + offset} L ${leftSide} ${coord.y-offset} Q ${leftSide} ${coord.y} ${leftSide + offset} ${coord.y} L ${coord.x} ${coord.y}`
                        } else {
                            path = path + ` L ${rightSide - offset},${prevCoord.y} Q${rightSide},${prevCoord.y} ${rightSide},${prevCoord.y + offset} L ${rightSide} ${coord.y-offset} Q ${rightSide} ${coord.y} ${rightSide - offset} ${coord.y} L ${coord.x} ${coord.y}`
                        }
                        
    
                        
                    } else {
                        path = path + ` L ${x} ${y}`
                    }
                   if (i === coordinates.length-1) {
                        path = path + ` L 0 ${coord.y}`
                    } 
                } else {
                    const prevCoord = coordinates[i-1]
                   
                    if (prevCoord.y !== coord.y) {
                  
                        if (coord.x > (window.innerWidth/2-1)) {
    
                            
                            path = path + ` L ${leftSide + offset},${prevCoord.y} Q${leftSide},${prevCoord.y} ${leftSide},${prevCoord.y + offset} L ${leftSide} ${coord.y-offset} Q ${leftSide} ${coord.y} ${leftSide + offset} ${coord.y} L ${coord.x} ${coord.y}`
                        } else {
                            path = path + ` L ${rightSide - offset},${prevCoord.y} Q${rightSide},${prevCoord.y} ${rightSide},${prevCoord.y + offset} L ${rightSide} ${coord.y-offset} Q ${rightSide} ${coord.y} ${rightSide - offset} ${coord.y} L ${coord.x} ${coord.y}`
                        }
                        
    
                        
                    } else {
                        path = path + ` L ${x} ${y}`
                    }
                   if (i === coordinates.length-1) {
                        path = path + ` L 0 ${coord.y}`
                    } 
                }
            }
        })







        return path
    }


 


    return <SVGContainer width={size.width} height={size.height} style={{
        top: size.top
    }}>
        <path
        id='line'
        d={buildPath(coordinates)}

        fill='none'
        />
    </SVGContainer>
}




export default SVGLine


