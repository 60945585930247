import { BottomFront, BottomSide, NumberContainer, NumbersContainer, TopBack, TopFront, TopSide } from "./tablo.styles"

import BGPng from '../../../assets/numbers/bg.png'
import PNG0 from '../../../assets/numbers/0.png'
import PNG1 from '../../../assets/numbers/1.png'
import PNG2 from '../../../assets/numbers/2.png'
import PNG3 from '../../../assets/numbers/3.png'
import PNG4 from '../../../assets/numbers/4.png'
import PNG5 from '../../../assets/numbers/5.png'
import PNG6 from '../../../assets/numbers/6.png'
import PNG7 from '../../../assets/numbers/7.png'
import PNG8 from '../../../assets/numbers/8.png'
import PNG9 from '../../../assets/numbers/9.png'
import { createRef, useEffect, useState } from "react"
import ShadowPNG from '../../../assets/numbers/shadow.png'
import EmptyPNG from '../../../assets/numbers/empty.png'

const images: { [key: number]: any } = {
    0: PNG0,
    1: PNG1,
    2: PNG2,
    3: PNG3,
    4: PNG4,
    5: PNG5,
    6: PNG6,
    7: PNG7,
    8: PNG8,
    9: PNG9,
}


const Tablo = ({ list, animate, empty, delay }: {
    list: number[],
    animate: boolean,
    empty?: boolean,
    delay: number
}) => {





    const rotationImages = list

    const [rotate, setRotate] = useState(-1)
    const [refs, setRefs] = useState<any[]>([])


    useEffect(() => {
        const refs = rotationImages.map(() => {
            return createRef()
        })
        setRefs(refs)
    }, [])


    useEffect(() => {
        if (animate) {
            turn()
        }
    }, [animate])

    const turn = () => {
        setRotate(c => c < rotationImages.length - 2 ? c + 1 : rotationImages.length - 2)
    }


    const isFacebookApp = () => {
        var ua = navigator.userAgent || navigator.vendor;
        return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1);
//         return true
    }



    return (
        <>
            {
                empty ?
                    <NumbersContainer >
                        <img src={ShadowPNG} alt="shadow" />
                        <NumberContainer>
                            <img src={EmptyPNG} alt="empty" />
                        </NumberContainer>
                    </NumbersContainer>
                    :
                    <NumbersContainer>
                        <img src={ShadowPNG} alt="shadow" />
                        {
                            isFacebookApp() ?
                                <NumberContainer style={{
                                    zIndex: 1
                                }}>
                                    <TopSide delay={0} rotation={false} duration={0}>
                                        <TopFront>
                                            <img src={images[list[rotationImages.length - 1]]} alt={'number'} />
                                        </TopFront>
                                        {/* <TopBack>
                                    <img src={images[list[rotationImages.length-1]]} alt={'number'} />
                                </TopBack> */}
                                    </TopSide>
                                </NumberContainer>
                                : rotationImages.map((index, i) => {

                                    return (
                                        <NumberContainer ref={refs.length > i ? refs[i] : undefined} key={i} style={{
                                            zIndex: rotationImages.length - i
                                        }} onTransitionEnd={() => {
                                            if (i === 0) {
                                                refs[0].current.style.zIndex = 1
                                            }
                                            turn()
                                            setTimeout(() => {
                                                if (i < rotationImages.length - 1) {

                                                    refs[i + 1].current.style.zIndex = i + 2
                                                }
                                            }, ((i) * 5 + 20) / 2)
                                        }}>
                                            <TopSide delay={delay} rotation={i <= rotate} duration={(i) * 5 + 20}>
                                                <TopFront>
                                                    <img src={images[rotationImages[i]]} alt={index.toString()} />
                                                </TopFront>
                                                <TopBack>
                                                    <img src={i < rotationImages.length - 1 ? images[rotationImages[i + 1]] : images[rotationImages[i]]} alt={index.toString()} />
                                                </TopBack>
                                            </TopSide>
                                        </NumberContainer>
                                    )
                                })
                        }
                        {
                            isFacebookApp() ?

                                <NumberContainer style={{
                                    zIndex: 10
                                }}>
                                    <BottomSide delay={0} rotation={false} duration={0}>
                                        <BottomFront>
                                            <img src={images[list[rotationImages.length - 1]]} alt={'number'} />
                                        </BottomFront>

                                    </BottomSide>
                                </NumberContainer>
                                :
                                <NumberContainer style={{
                                    zIndex: 0
                                }}>
                                    <TopSide delay={0} rotation={true} duration={0}>
                                        <TopFront>
                                            <img src={images[list[0]]} alt={'number'} />
                                        </TopFront>

                                        <TopBack>
                                            <img src={images[list[rotationImages.length - 1]]} alt={'number'} />
                                        </TopBack>

                                    </TopSide>
                                </NumberContainer>
                        }
                        <img src={BGPng} alt='bg' />
                    </NumbersContainer>
            }
        </>
    )
}



export default Tablo
