import styled from "styled-components";




export const FooterWrapper = styled.footer`
width: 100%;
height: 17.75rem;
display: flex;
justify-content: center;
align-items: center;
background: ${props => props.theme.palette.colors.red};


@media(max-width: 1000px) {
   height: auto;
   padding: 3rem 0rem 4.25rem 0rem;
}
`



export const FooterContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
`


export const SocialsRow = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
>a {
    cursor: pointer;
    &:not(:last-of-type) {
        
        margin-right: 4rem;
        }
}
margin-bottom: 2.375rem;


@media(max-width: 1000px) {
    margin-bottom: 3rem;
}
`


export const ContactsRow = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
>a {
    white-space: nowrap;
    color: ${props => props.theme.palette.colors.white};
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    &:first-of-type {
        margin-right: 0.625rem;
    }
}
margin-bottom: 1.5rem;


@media(max-width: 1000px) {
    flex-direction: column;
    margin-bottom: 2.5rem;
    >a {
    color: ${props => props.theme.palette.colors.white};
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    &:first-of-type {
        margin-right: 0rem;
        margin-bottom: 1.5rem;
    }
}
}
`



export const AgencyRow = styled.span`


font-size: 1rem;
color: ${props => props.theme.palette.colors.white};
font-weight: 600;
display: flex;
align-items: center;
>a {
    cursor: pointer;
        font-weight: 500;
        font-style: italic;
        color: #00A3FF;
        margin-left: 5px;
}
margin-bottom: 2rem;
text-align: center;




@media(max-width: 1000px) {
 margin-bottom: 0rem;
 display: inline;
}
`



export const NavigationRow = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
>span {
    font-weight: 600;
font-size: 0.875rem;
color: ${props => props.theme.palette.colors.white};
cursor: pointer;
    &:not(:last-of-type) {
        margin-right: 2.5rem;
    }
}


@media(max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 2.5rem;
    >span {
        margin-right: 0rem;
        &:not(:last-of-type) {
            margin-right: 0rem;
            margin-bottom: 0.875rem;
        }

    }
}

`