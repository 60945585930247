import styled from "styled-components";


export const NavigationSectionWrapper = styled.div`
display: flex;
width: 100%;

flex-direction: column;
align-items: center;
background: no-repeat center 160%/cover url(navigation-bg.png);
z-index: 1;
position: relative;
padding-top: 6rem;

@media (max-width: 1000px) {
    background: no-repeat  0% 0%/180% url(navigation-bg.png);
    padding-top: 0rem;
}
`


export const NavigationSectionContainer = styled.div`
width: 100%;
max-width: 120rem;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;

@media (max-width: 1000px) {
    > img {
        width: 100%;
    }

}
`



export const NavigationSectionTitle = styled.div`
    margin-left: 16%;
    display: flex;
    align-items: center;
    align-self: flex-start;
    >div {
        height: 2px;
        width: 4.675rem;
        border-radius: 0.625rem;
        background: ${props => props.theme.palette.colors.red};
    }
    >h5 {
        margin-left: 1.5rem;
        color: ${props => props.theme.palette.colors.white};
        font-weight: bold;
        font-size: 4rem;
    }



    @media(max-width: 1000px) {
        margin-left: 0.25rem;
        >div {
            width: 0.5rem;
        }
        >h5 {
            font-size: 1.25rem;
            margin-left: 0.25rem;
        }
    }
`


