import { AdvantagesSectionContainer, AdvantagesSectionTitle, AdvantagesSectionWrapper, CirclesContainer, InfoCircle, InfoCircleHighlight, InfoCircleMobileHighlight, InfoCircleMobileText, InfoCircleText, RequestButton } from "./advantagesSection.styles"

import { ReactComponent as GearsSVG } from '../../assets/gears.svg'

import { ReactComponent as ChatSVG } from '../../assets/chat.svg'

import { ReactComponent as DevicesSVG } from '../../assets/devices.svg'
import { useInView } from "react-intersection-observer"
import { Routes } from "../Header/header"
import { useEffect } from "react"
import NavigationLines from "../NavigationLines/navigationLines"
import { useWindowDimensions } from "../../hooks/dimensions"



const AdvantagesSection = ({ onSelectSection, sendRequest }: { onSelectSection: (route: Routes) => void, sendRequest: () => void }) => {
    const { width } = useWindowDimensions()
    const { inView, ref } = useInView({
        threshold: 0.3
    })

    useEffect(() => {
        if (inView) {
            onSelectSection(Routes.ADVANTAGES)
        }
    }, [inView])


    return (
        <AdvantagesSectionWrapper id='Преимущества' ref={ref}>
            {
                width > 1000 &&
                <NavigationLines
                    selectedSection={Routes.ADVANTAGES}
                    color="#D5333E"
                    top="50%"


                />
            }
            <AdvantagesSectionContainer>
                <AdvantagesSectionTitle>
                    <h5>
                        ПРЕИМУЩЕСТВА
                    </h5>
                    <div />
                </AdvantagesSectionTitle>
                {
                    width > 1000 ?

                        <CirclesContainer>
                            <InfoCircle radius={width > 1400 ? "33.5rem" : '28rem'} zIndex={1}>
                                <DevicesSVG width={width > 1400 ? '12rem' : '10rem'} height={width > 1400 ? '10.625rem' : '8.5rem'} />
                                <InfoCircleText>

                                    Специально разработанная<br /> онлайн-платформа.
                                </InfoCircleText>
                                <InfoCircleHighlight>
                                    Аналогов нет!
                                </InfoCircleHighlight>

                            </InfoCircle>
                            <div style={{
                                width: width > 1400 ? '24rem' : '18rem'
                            }}></div>
                            <InfoCircle radius={width > 1400 ? "33.5rem" : '28rem'} zIndex={1}>
                                <GearsSVG width={width > 1400 ? '11rem' : '9.7rem'} height={width > 1400 ? '10.625rem' : '8.5rem'} />
                                <InfoCircleText>
                                    <InfoCircleHighlight>
                                        Кастомизируемость
                                    </InfoCircleHighlight>
                                    (платформа, сценарий,<br /> задания, формат).

                                </InfoCircleText>

                            </InfoCircle>
                            <div style={{
                                zIndex: 2
                            }}>
                                <InfoCircle radius={width > 1400 ? "41.25rem" : "33rem"} zIndex={2}>
                                    <ChatSVG width={width > 1400 ? '12rem' : '10rem'} height={width > 1400 ? '10.625rem' : '8.5rem'} />
                                    <InfoCircleText style={{
                                        maxWidth: width > 1400 ? "25.25rem" : '28rem'
                                    }}>
                                        Собственный отдел разработки уникальных сценариев:

                                    </InfoCircleText>
                                    <InfoCircleHighlight>
                                        это будет игра специально для вас.
                                    </InfoCircleHighlight>

                                </InfoCircle>
                            </div>
                        </CirclesContainer>

                        :


                        <CirclesContainer>
                            <InfoCircle radius={`${width * 0.7}px`} zIndex={2}>
                                <ChatSVG width={'4rem'} height={'4.5rem'} />
                                <InfoCircleMobileText style={{
                                    maxWidth: '80%'
                                }}>
                                    Собственный отдел разработки уникальных сценариев:

                                    <InfoCircleMobileHighlight>
                                        {' '}это будет игра специально для вас.
                                    </InfoCircleMobileHighlight>
                                </InfoCircleMobileText>

                            </InfoCircle>
                            <div style={{
                                height: '6rem'
                            }}></div>
                            <InfoCircle radius={`${width * 0.71}px`} zIndex={0}>
                                <GearsSVG width={'4.25rem'} height={'4.25rem'} />
                                <InfoCircleText>
                                    <InfoCircleHighlight>
                                        Кастомизируемость
                                    </InfoCircleHighlight>
                                    (платформа, сценарий,<br /> задания, формат).

                                </InfoCircleText>

                            </InfoCircle>
                            <div style={{
                                zIndex: 1
                            }}>
                                <InfoCircle radius={`${width * 0.7}px`} zIndex={1}>
                                    <DevicesSVG width={'4.25rem'} height={'3.65rem'} />
                                    <InfoCircleText>

                                        Специально разработанная<br /> онлайн-платформа.
                                    </InfoCircleText>
                                    <InfoCircleHighlight>
                                        Аналогов нет!
                                    </InfoCircleHighlight>

                                </InfoCircle>

                            </div>
                        </CirclesContainer>
                }

                <RequestButton onClick={sendRequest}>
                    ОТПРАВИТЬ ЗАПРОС
                </RequestButton>
            </AdvantagesSectionContainer>
        </AdvantagesSectionWrapper>
    )
}




export default AdvantagesSection



