import styled from 'styled-components'



export const AboutSectionWrapper = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
width: 100%;
margin-top: 3rem;
/* background: no-repeat center top/cover url(redbluebg.png); */
position: relative;

`






export const AboutSectionContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
position: relative;
`


export const AboutSectionDescriptionWrapper = styled.div`
/* background: no-repeat top center/cover url(redbg.png); */
width: 100%;
padding-bottom: 10rem;
z-index: 1;
position: relative;
@media(max-width: 1000px) {
    padding-bottom: 0rem;
}
`

export const AboutSectionRedOverlay = styled.div`
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: ${props => props.theme.palette.colors.red};
clip-path: polygon(0% 15%, 0% 95%, 100% 85%, 100% 5% );



@media(max-width: 1000px) {
    clip-path: polygon(0% 10%, 0% 95%, 100% 90%, 100% 5% );
}

`



export const AboutSectionDescription = styled.div`
width: 100%;
max-width: 120rem;
position: relative;
padding: 10.875rem 5.5rem 0rem 15rem;
display: flex;
flex-direction: row;
align-items: center;
>svg {
    position: absolute;
    top: -5rem;
    right: 0;


    @media(max-width: 1400px) {
        top: -2.5rem;
    }
}

@media(max-width: 1000px) {
    padding: 6rem 0rem 15rem 1.5rem;
    height: 100%;
    flex-direction: column;
    >svg {
        bottom: -10rem;
        top: inherit;
        right: -5%;
    }
}
`

export const AboutSectionText =  styled.div`
display: flex;
flex-direction: row;
align-items: flex-start;
max-width: 48.125rem;
>svg {
    margin-right: 3.5rem;
}

>div {
    display: flex;
    flex-direction: column;
    > span {
        display: flex;
        max-width: 47.5rem;
        font-size: 1.875rem;
        font-weight: 400;
        color: ${props => props.theme.palette.colors.white};
        line-height: 140%;

        &:first-of-type {
            font-weight: 600;
            margin-bottom: 2rem;
        }



        @media(max-width: 1400px) {
            font-size: 1.5rem;
        }
    }
}
@media(max-width: 1400px) {
            max-width: 45rem;
        }


        @media(max-width: 1000px) {
            >div {
                >span {
                    font-size: 1rem;
                    padding-right: 1rem;

                }
            }
    flex-direction: column;
    align-items: center;
    >svg {
        margin-right: 1.5rem;
        margin-bottom: 2rem;
    }

}
`



export const InfoContainer = styled.div`
display: flex ;
flex-direction: row;

width: 100%;

max-width: 120rem;
position: relative;
top: -10rem;

@media(max-width: 1000px) {
    top: 0rem;
    margin-top: 10rem;
    flex-direction: column;
}

`


export const AboutSectionTitle = styled.div`
    padding-top: 20rem;
    background: ${props => props.theme.palette.colors.black};
    border-bottom-right-radius: 3.75rem;
        width: 50%;
    >div {
        width: 100%;
        padding-left: 32%;
        display: flex;
        align-items: center;
        align-self: flex-start;
        margin-bottom: 7rem;
        >div {
            height: 2px;
            width: 4.675rem;
            border-radius: 0.625rem;
            align-self: flex-start;
            background: ${props => props.theme.palette.colors.red};
            position: relative;
            top: 2.4rem;
        }
        >h5 {
            margin-left: 1.5rem;
            color: ${props => props.theme.palette.colors.white};
            font-weight: bold;
            font-size: 4rem;
            line-height: 120%;
            text-transform: uppercase;
    }

    }


    @media(max-width: 1000px) {
    margin-top: 2rem;
    padding-top: 0rem;
    clip-path: polygon(0% 0%, 0% 92%, 100% 100%, 100% 8% );
    border-bottom-right-radius: 0rem;
    width: 100%;
    height: 12rem;
        display: flex;
        align-items: center;
       >div {
           align-self: center;
           padding-left: 0;
           margin-left: 0.25rem;
           
           
           margin-bottom: 2rem;
           >div {
               width: 0.5rem;
               top: 0.75rem;
           }
           >h5 {
               font-size: 1.25rem;
               margin-left: 0.25rem;
               
           }

       } 

    
}
`


export const CompanySection = styled.div`
padding-left: 5rem;
display: flex;
flex-direction: column;
padding-top: 20rem;
padding-bottom: 4rem;
>svg {
    margin-bottom: 3.5rem;
    
}


>h6 {
    font-size: 1.875rem;
    font-weight: bold;
    line-height: 150%;
    margin-bottom: 2.375rem;
    color: ${props => props.theme.palette.colors.black};
}

>span {
    text-align: start;
    align-self: flex-start;
    line-height: 140%;
    font-weight: 400;
    font-size: 1rem;
    color: ${props => props.theme.palette.colors.black};
    >strong {
        font-weight: 800;
        font-size: 1.875rem;
        color: ${props => props.theme.palette.colors.red};
        margin-right: 5px;   }
    >span {
        font-weight: 800;
        margin-left: 5px;
    }
    &:not(:last-of-type) {
        margin-bottom: 1rem;
    }
}



@media(max-width: 1000px) {
    padding: 2rem 1rem 4.5rem 1rem;
    
    align-items: center;
    text-align: center;
    >span {
        &:not(:last-of-type) {
        margin-bottom: 1.6rem;
    }
    }
}

`

export const RequestButton = styled.button`
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
width: 13.4375rem;
height: 3.4375rem;
background: ${props => props.theme.palette.colors.red};
cursor: pointer;
color: ${props => props.theme.palette.colors.white};
font-weight: 600;
font-size: 0.875rem;
line-height: 120%;
/* identical to box height */
border-radius: 2rem;
text-transform: uppercase;
align-self: center;



`