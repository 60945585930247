import styled from 'styled-components'


export const GoalsSectionWrapper = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
width: 100%;
margin-bottom: 3rem;
position: relative;
`



export const GoalsSectionContainer = styled.div`
width: 100%;
max-width: 120rem;
display: flex;
flex-direction: column;
`


export const GoalsSectionTitle = styled.div`
    margin-left: 16%;
    display: flex;
    align-items: center;
    align-self: flex-start;
    margin-bottom: 7rem;
    >div {
        height: 2px;
        width: 4.675rem;
        border-radius: 0.625rem;
        align-self: flex-start;
        background: ${props => props.theme.palette.colors.red};
        position: relative;
        top: 2.4rem;
    }
    >h5 {
        margin-left: 1.5rem;
        color: ${props => props.theme.palette.colors.black};
        font-weight: bold;
        font-size: 4rem;
        line-height: 120%;
        text-transform: uppercase;
    }


    @media(max-width: 1000px) {
        margin-left: 0.25rem;
        margin-right: 0rem;
        align-self: flex-start;
        margin-bottom: 2.75rem;
        >div {
            width: 0.5rem;
            top: 0.75rem;
        }
        >h5 {
            font-size: 1.25rem;
            margin-left: 0.25rem;
            
        margin-right: 0rem;
        }
    }
`



export const GoalsGrid = styled.div`
display: flex;
flex-direction: row;
align-items: flex-start;
justify-content: center;

`

export const GoalsColumn = styled.div`
display: flex;
flex-direction: column;
align-items: center;

`


export const GoalsItem = styled.div`
display: flex;
flex-direction: column;
align-items: center;

height: 19rem;
&:first-of-type {
    height:21rem;

}


@media(max-width: 1000px) {
    height: auto;
&:first-of-type {
    height:auto;

}
&:not(:last-of-type) {
    margin-bottom: 5rem;
}
}

`


export const GoalsBody = styled.div`
text-align: center;
position: relative;
text-align: center;
line-height: 140%;
font-size: 1.25rem;
font-weight: 500;
max-width: 27.813rem;

color: ${props => props.theme.palette.colors.black};
>span {

    >strong {
        font-weight: 600;
        color: ${props => props.theme.palette.colors.red};
    }
}
>div {
    position: absolute;
    top: 0.9rem;
    left: 0;
    width: 2rem;
    height: 0.125rem;
    border-radius: 0.5rem;
    background: ${props => props.theme.palette.colors.red};

}


@media(max-width: 1000px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    max-width: 80%;
    margin-top: 3.7rem!important;
    >div {
    position: absolute;
    top: -2.625rem;
    left: initial;
    width: 2px;
    height: 1.875rem;
    border-radius: 0.5rem;
    background: ${props => props.theme.palette.colors.black};
        left: inherit!important;
}
}

`


export const GoalsDivider = styled.div`
width: 2px;
min-width: 2px;
height: 100%;
border-radius: 0.5rem;
    background: ${props => props.theme.palette.colors.red};
    margin: 0 7.25rem;
`



export const PostTitle = styled.h6`
text-align: center;
margin-top: 4rem;
font-size: 3.125rem;
color: ${props => props.theme.palette.colors.red};
font-weight: bold;
line-height: 140%;


@media(max-width: 1000px) {
    font-size: 1.875rem;
}
`