import { ReactComponent as LogoSvg } from '../../assets/logo.svg'
import { ReactComponent as LogoRedSvg } from '../../assets/logo-red.svg'
import { ReactComponent as BurgerSvg } from '../../assets/burger.svg'
import { ReactComponent as CrossSvg } from '../../assets/cross.svg'
import { ContactLink, CrossContainer, HeaderContainer, HeaderWrapeer, LogoContainer, MobileContactsContainer, MobileHeaderContainer, MobileHeaderMenu, MobileMenuLogo, MobileNavigationContainer, MobileNavigationOption, NavigationContainer, NavigationOption, NavigationOptionsContainer, RedRequestButton, RequestButton, SocialsRow } from './header.styles'
import { useState } from 'react'
import { useWindowDimensions } from '../../hooks/dimensions'
import { Modal, ModalContent } from '@chakra-ui/react'

import { ReactComponent as TGSVg } from '../../assets/tg.svg'
import { ReactComponent as ShareSVg } from '../../assets/share.svg'
import { ReactComponent as InstaSVg } from '../../assets/insta.svg'


export enum Routes {
    GAME = 'Игры',
    NAVIGATION = 'Навигация',
    ADVANTAGES = 'Преимущества',
    GAMEPLAY = 'Механика',
    GOALS = 'Задачи',
    AUTHORS = 'Авторы',
    CONTACTS = 'Контакты'
}

interface HeaderProps {

    selectedSection: Routes,
    onSelectSection: (route: Routes, navigate?: boolean) => void,
    showRequestModal: () => void
}




const Header: React.FC<HeaderProps> = ({
    onSelectSection,
    selectedSection,
    showRequestModal
}) => {

    const { width } = useWindowDimensions()
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)







    return (
        <HeaderWrapeer id='app-header'>
            <HeaderContainer>

                <LogoContainer>
                    <LogoSvg />
                </LogoContainer>
                <NavigationContainer>
                    {
                        width > 1000 ?
                            <>
                                <NavigationOptionsContainer>
                                    {
                                        (Object.keys(Routes) as Array<keyof typeof Routes>).map((key, index) => {
                                            
                                            return <NavigationOption onClick={() => onSelectSection(Routes[key] as Routes, true)} key={index} selected={selectedSection === (Routes[key] as Routes)}>
                                                {Routes[key]}
                                                <span>
                                                    {Routes[key]}
                                                </span>
                                            </NavigationOption>
                                        })
                                    }
                                </NavigationOptionsContainer>
                                <RequestButton onClick={showRequestModal}>
                                    ОТПРАВИТЬ ЗАПРОС
                                </RequestButton>
                            </>
                            : <BurgerSvg onClick={() => {
                                setMobileMenuOpen(true)
                            }} style={{

                                cursor: 'pointer',


                            }} />
                    }

                </NavigationContainer>
            </HeaderContainer>
            <Modal size={'full'} isOpen={mobileMenuOpen && width < 1000} onClose={() => setMobileMenuOpen(false)}>
                <ModalContent borderRadius={0}>
                    <MobileHeaderContainer>
                        <MobileHeaderMenu>
                            <MobileMenuLogo>
                                <LogoRedSvg />
                                <CrossContainer onClick={() => setMobileMenuOpen(false)}>
                                    <CrossSvg />
                                </CrossContainer>
                            </MobileMenuLogo>
                            <MobileNavigationContainer>
                                {
                                    (Object.keys(Routes) as Array<keyof typeof Routes>).map((key, index) => {
                                        
                                        return <MobileNavigationOption onClick={() => {
                                            setMobileMenuOpen(false)
                                            onSelectSection(Routes[key] as Routes, true)
                                        }
                                        } key={index} >
                                            {Routes[key]}

                                        </MobileNavigationOption>
                                    })
                                }
                            </MobileNavigationContainer>
                        </MobileHeaderMenu>
                        <MobileContactsContainer>
                            <SocialsRow>
                            <a target='_blank' rel='noopener noreferrer' href='https://t.me/weareweagency'>
                        <TGSVg />

                    </a>
                    <a target='_blank' rel='noopener noreferrer' href='https://www.instagram.com/onlinetmbl/'>
                        <InstaSVg />

                    </a>
                    {/* <a target='_blank' rel='noopener noreferrer'>
                        <ShareSVg />

                    </a> */}
                            </SocialsRow>
                            <ContactLink href='tel:79161830708' target={'_blank'} rel='noopener noreferrer'>
                                +7 916 183-07-08
                            </ContactLink>
                            <ContactLink href='mailto:ms@we-group.ru' target={'_blank'} rel='noopener noreferrer'>
                                ms@we-group.ru
                            </ContactLink>
                            <RedRequestButton onClick={() => {
                                setMobileMenuOpen(false)
                                showRequestModal()
                            }}>
                                ОТПРАВИТЬ ЗАПРОС
                            </RedRequestButton>
                        </MobileContactsContainer>
                    </MobileHeaderContainer>
                </ModalContent>
            </Modal>
        </HeaderWrapeer>

    )
}



export default Header 