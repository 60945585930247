import { background, Modal, ModalContent, ModalOverlay } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { ArrowLeft, ArrowRight, ButtonsContainer, CarouselContainer, CarousleInnerWrapper, CarousleWrapper, CloseContainer, ContentContainer, DescriptionHightlight, DescriptionRedHightlight, DescriptionText, Dot, DownloadButton, Line, RequestButton, SectionContainer, SlideContainer, SVGContainer, TitleContainer } from "./avatarModal.styles"
import { ReactComponent as CloseSVG } from '../../../assets/cross.svg'
import { ReactComponent as ArrowSVG } from '../../../assets/arrow-right.svg'
import { ReactComponent as LocationSVG } from '../../../assets/location.svg'
import { ReactComponent as DownloadSVG } from '../../../assets/download.svg'
import { ReactComponent as PeopleSVG } from '../../../assets/people.svg'
import Carousel, { slidesToShowPlugin, Dots } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import SlideOne from '../../../assets/slide1.png'
import Slide2 from '../../../assets/slide2.png'
import Slide3 from '../../../assets/slide3.png'
import Slide4 from '../../../assets/slide4.png'
import Slide5 from '../../../assets/slide5.png'
import Slide6 from '../../../assets/slide6.png'
import Slide7 from '../../../assets/slide7.png'
import Slide8 from '../../../assets/slide8.png'
import { useWindowDimensions } from "../../../hooks/dimensions"



interface ModalProps {
    open: boolean,
    onClose: () => void,
    sendRequest: () => void
}




const AvatarModal: React.FC<ModalProps> = ({
    onClose,
    open,
    sendRequest
}) => {



    const {width} = useWindowDimensions()
    // useEffect(() => {
    //     const header = document.querySelector('header')
    //     if (header ) {
    //         if (open) {
    //         header.style.paddingRight='8px'   
    //         } else {
    //             header.style.paddingRight='0px'
    //         }

    //     }
    // }, [open])

    const [currentSlide, setCurrentSlide] = useState(0)



    const onSlideChange = (value: number) => {
        setCurrentSlide(value);
    }


    return (
        <Modal isOpen={open} onClose={onClose} size={width< 1001 ? 'full': undefined} scrollBehavior="outside" blockScrollOnMount={false}>
            <ModalOverlay
                backdropFilter={'blur(1rem)'} background='#fafafa44' />
            <ModalContent background={'transparent'} width={'100%'} maxW={'61.25rem'} borderRadius={'3rem'}>
                <ContentContainer>
                    <CloseContainer onClick={onClose}>
                        <CloseSVG />

                    </CloseContainer>
                    <Line style={width<1000 ? {left: '2rem', height: '1.875rem', width: '2px'} : undefined}/>
                    <TitleContainer>
                        Аватар
                    </TitleContainer>
                    <DescriptionText style={width< 1000 ? {padding: '0rem 1rem'} : undefined}>
                        Просто представьте, игроки находятся в разных уголках страны (а то и мира), но все вместе   решают задачи в одной конкретной точке пространства. Потому что           у них есть общий аватар, их руки, ноги и глаза на месте событий. А руками и ногами управляет мозг - команда участников.

                    </DescriptionText>
                    <SectionContainer>
                        <SVGContainer>
                            <LocationSVG />
                        </SVGContainer>
                        <DescriptionText>

                            <DescriptionHightlight>

                                Локация:
                            </DescriptionHightlight>
                            <DescriptionRedHightlight>

                                {' '}практически любая!
                            </DescriptionRedHightlight>
                        </DescriptionText>
                        <DescriptionText>
                        Сейчас доступны готовые игры в  <DescriptionHightlight>Москве</DescriptionHightlight> {' '} (центр города или локальная игра в бункере), <DescriptionHightlight>
                                Петербурге
                            </DescriptionHightlight>
                            , <DescriptionHightlight>Торжке</DescriptionHightlight>,{' '} <DescriptionHightlight>Стамбуле</DescriptionHightlight>{' '} (Турция),<DescriptionHightlight>{' '}Дуле</DescriptionHightlight>  (Бельгия).

                        </DescriptionText>
                        <DescriptionText>
                        А ещё мы можем сделать игру там, где вам захочется побывать!
                            {/* И мы можем поставить трассу специально для Вашей игры там, где захочется Вам! */}
                        </DescriptionText>

                    </SectionContainer>
                    <SectionContainer>
                        <SVGContainer>
                            <PeopleSVG />
                        </SVGContainer>
                        <DescriptionText>
                            <DescriptionHightlight>

                                Количество участников:
                            </DescriptionHightlight>
                            {' '}
                            <DescriptionRedHightlight>
                                10-300
                            </DescriptionRedHightlight>
                            {' '}
                            (в зависимости от локации).
                        </DescriptionText>
                        <DescriptionText>
                            <DescriptionHightlight>

                                В команде:
                            </DescriptionHightlight>
                            {' '}
                            до
                            {' '}
                            <DescriptionRedHightlight>
                                10
                            </DescriptionRedHightlight>
                            {' '}
                            человек.
                        </DescriptionText>
                        <DescriptionText>
                            <DescriptionHightlight>

                                Продолжительность игры:
                            </DescriptionHightlight>
                            {' '}
                            от
                            {' '}
                            <DescriptionRedHightlight>
                                2
                            </DescriptionRedHightlight>
                            {' '}
                            часов и до бесконечности!
                        </DescriptionText>
                    </SectionContainer>
                    <CarousleWrapper>
                        {
                            currentSlide>0 && width>1000 && <ArrowLeft onClick={ () => setCurrentSlide(c => c-1)}>
                                <ArrowSVG/>
                            </ArrowLeft>
                        }
                                            {
                            currentSlide<7 && width>1000 && <ArrowRight onClick={ () => setCurrentSlide(c => c+1)}>
                                <ArrowSVG/>
                            </ArrowRight>
                        }
                        <CarousleInnerWrapper>
                        <CarouselContainer>
                            <Carousel

                                value={currentSlide}
                                onChange={onSlideChange}
                                plugins={[

                                    'fastSwipe',
                                    {
                                        resolve: slidesToShowPlugin,
                                        options: {
                                            numberOfSlides: 1
                                        }
                                    },
                                ]}
                            >
                                <SlideContainer>
                                    <img src={SlideOne} />

                                </SlideContainer>
                                <SlideContainer>
                                    <img src={Slide2} />

                                </SlideContainer>
                                <SlideContainer>
                                    <img src={Slide3} />

                                </SlideContainer>
                                <SlideContainer>
                                    <img src={Slide4} />

                                </SlideContainer>
                                <SlideContainer>
                                    <img src={Slide5} />

                                </SlideContainer>
                                <SlideContainer>
                                    <img src={Slide6} />

                                </SlideContainer>
                                <SlideContainer>
                                    <img src={Slide7} />

                                </SlideContainer>
                                <SlideContainer>
                                    <img src={Slide8} />

                                </SlideContainer>
                            </Carousel>
                        </CarouselContainer>
                        </CarousleInnerWrapper>
                    </CarousleWrapper>
                    <Dots
                        value={currentSlide}
                        onChange={onSlideChange}
                        thumbnails={[
                            <Dot active={currentSlide === 0} />,
                            <Dot active={currentSlide === 1} />,
                            <Dot active={currentSlide === 2} />,
                            <Dot active={currentSlide === 3} />,
                            <Dot active={currentSlide === 4} />,
                            <Dot active={currentSlide === 5} />,
                            <Dot active={currentSlide === 6} />,
                            <Dot active={currentSlide === 7} />,
                        ]}
                    />
                    <ButtonsContainer>
                        <DownloadButton href='/OnlineTeambuilding_Avatar_151221_WE.pdf' target={'_blank'} rel='noopener noreferrer'>
                            <DownloadSVG/>
                            СКАЧАТЬ ПРЕЗЕНТАЦИЮ <br/>
С ГОТОВЫМИ СЦЕНАРИЯМИ
                        </DownloadButton>
                        <RequestButton onClick={() => {
                            onClose()
                            sendRequest()
                        }}>
                            ОТПРАВИТЬ ЗАПРОС
                            </RequestButton>
                        </ButtonsContainer>
                </ContentContainer>
            </ModalContent>
        </Modal>
    )
}



export default AvatarModal