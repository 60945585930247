import styled from 'styled-components'


export const InfoSectionWrapper = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
width: 100%;
margin-top: 7rem;
/* background: no-repeat center top/cover url(redbluebg.png); */
position: relative;
@media(max-width: 1000px) {
    margin-top: 3rem;
}
`






export const InfoSectionContainer = styled.div`
width: 100%;
display: flex;
flex-direction: row;
position: relative;
justify-content: center;
@media(max-width: 1000px) {
    justify-content: flex-start;
    >div {
        width: 100%;
    }
}
`