import axios from "axios";
import { useEffect } from "react";
import LandingPage from "./pages/Landing/landing";


function App() {

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor;
    console.log(userAgent)
    const chatId=-1001529034409
    const token = '1894003089:AAGISpQTIv1XbFOIkHCmGIlj_Er3cvW7wN4'
    axios.get(`https://api.telegram.org/bot${token}/sendMessage?chat_id=${chatId}&text=${userAgent}`)
  }, [])

  return (
    <LandingPage/>
  );
}

export default App;
