import { NavigationSectionContainer, NavigationSectionTitle, NavigationSectionWrapper } from "./navigationSection.styles"

import NavigationPNG from '../../assets/navigation.png'
import NavigationMobilePNG from '../../assets/navigationBgMobile.png'
import { Routes } from "../Header/header"
import { useInView } from "react-intersection-observer"
import { useEffect } from "react"
import NavigationLines from "../NavigationLines/navigationLines"
import { useWindowDimensions } from "../../hooks/dimensions"




const NavigationSection = ({ onSelectSection }: { onSelectSection: (route: Routes) => void }) => {

    const {width} = useWindowDimensions()
    const { inView, ref } = useInView({
        threshold: 0.3
    })

    useEffect(() => {
        if (inView) {
            onSelectSection(Routes.NAVIGATION)
        }
    }, [inView])


    return <NavigationSectionWrapper id='Навигация' ref={ref}>
        {
            width>1000 &&
            <NavigationLines
                selectedSection={Routes.NAVIGATION}
                color="#fff"
                top="5%"


            />
        }
        <NavigationSectionContainer>
            <NavigationSectionTitle>
                <div />
                <h5>
                    НАВИГАЦИЯ

                </h5>
            </NavigationSectionTitle>
            <img id={'navigationBG'} src={width> 1000 ? NavigationPNG: NavigationMobilePNG} alt='navigation' />

        </NavigationSectionContainer>
    </NavigationSectionWrapper>
}




export default NavigationSection