export const scaleElementToWidth = (width: number, size: number) => {
    const minScale = 0.66;
    const maxScale = 1;
    const minScreen = 900;
    const maxScreen = 1920;
  
    let w =
      width > minScreen ? (width <= maxScreen ? width : maxScreen) : minScreen;
  
    const result =
      ((w - minScreen) / (maxScreen - minScreen)) * (maxScale - minScale) * size +
      minScale * size;
   
    return result;
  };