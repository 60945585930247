import { AwardListWrapper, AwardsList, AwardsSectionContainer, AwardsSectionTitle, AwardsSectionWrapper, DotsContainer } from "./awards.styles"
import Slider from "react-slick";

import { ReactComponent as Card1 } from '../../assets/card1.svg'
import { ReactComponent as Card4 } from '../../assets/card4.svg'
import { ReactComponent as Card3 } from '../../assets/card3.svg'
import { ReactComponent as Card2 } from '../../assets/card2.svg'
import { Routes } from "../Header/header";
import NavigationLines from "../NavigationLines/navigationLines";
import { scaleElementToWidth } from "../../utils/utils";
import { useWindowDimensions } from "../../hooks/dimensions";
import Carousel, { slidesToShowPlugin, Dots } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import { useState } from "react";
import { Dot } from "../MainSection/AvatarModal/avatarModal.styles";

const AwardsSection = () => {
    const [currentSlide, setCurrentSlide] = useState(0)


    const onSlideChange = (value: number) => {
        setCurrentSlide(value);
    }

    const { width } = useWindowDimensions()

    return (
        <AwardsSectionWrapper>
            {
                width > 1000 &&
                <NavigationLines
                    selectedSection={Routes.CONTACTS}
                    color="#D5333E"
                    top="50%"


                />
            }
            <AwardsSectionContainer>
                <AwardsSectionTitle>
                    <h5>
                        Лауреаты<br />
                        профессиональных<br />
                        премий
                    </h5>
                    <div />
                </AwardsSectionTitle>
                {/* <Slider
                    {...{
                        dots: true,
                        infinite: false,
                        centerMode: true,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        variableWidth: true,
                        className: 'awards-slider'
                    }}
                >
                    <div>
                        <Card1 />
                    </div>
                    <div>
                        <Card2 />
                    </div>
                    <div>
                        <Card3 />
                    </div>
                    <div>
                        <Card4 />
                    </div>
                </Slider> */}
                {
                    width > 1000 ?

                        <AwardsList>
                            <div>
                                <Card1 width={scaleElementToWidth(width, 209)} height={scaleElementToWidth(width, 413)} />
                            </div>
                            <div>
                                <Card2 width={scaleElementToWidth(width, 209)} height={scaleElementToWidth(width, 413)} />
                            </div>
                            <div>
                                <Card3 width={scaleElementToWidth(width, 209)} height={scaleElementToWidth(width, 413)} />
                            </div>
                            <div>
                                <Card4 width={scaleElementToWidth(width, 209)} height={scaleElementToWidth(width, 413)} />
                            </div>
                        </AwardsList>

                        :
                        <>
                        
                        <AwardsList>
                            <AwardListWrapper>
                            <Carousel

                                value={currentSlide}
                                onChange={onSlideChange}
                                plugins={[

                                    'fastSwipe',
                                    {
                                        resolve: slidesToShowPlugin,
                                        options: {
                                            numberOfSlides: 1
                                        }
                                    },
                                ]}
                            >
                                <div>
                                    <Card1 width={214} height={424} />
                                </div>
                                <div>
                                    <Card2 width={214} height={424} />
                                </div>
                                <div>
                                    <Card3 width={214} height={424} />
                                </div>
                                <div>
                                    <Card4 width={214} height={424} />
                                </div>
                            </Carousel>
                            </AwardListWrapper>
                        </AwardsList>
                        <DotsContainer>

<Dots
    value={currentSlide}
    onChange={onSlideChange}
    thumbnails={[
        <Dot active={currentSlide === 0} />,
        <Dot active={currentSlide === 1} />,
        <Dot active={currentSlide === 2} />,
        <Dot active={currentSlide === 3} />,
    ]}
/>
</DotsContainer>
                        </>
                }
            </AwardsSectionContainer>
        </AwardsSectionWrapper>
    )
}




export default AwardsSection