import { useState } from "react"
import AboutSection from "../../components/AboutSection/aboutSection"
import AdvantagesSection from "../../components/AdvantagesSection/advantagesSection"
import AwardsSection from "../../components/AwardsSection/awardsSection"
import Footer from "../../components/Footer/footer"
import GoalsSection from "../../components/GoalsSection/goalsSection"
import Header, { Routes } from "../../components/Header/header"
import InfoSection from "../../components/InfoSection/infoSection"
import MainSection from "../../components/MainSection/main-section"
import MechanicsSection from "../../components/MechanicsSection/mechanicsSection"
import NavigationSection from "../../components/NavigationSection/navigationSection"
import RequestModal from "../../components/RequestModal/requestModal"
import StatsSection from "../../components/StatsSection/statsSection"
import SVGLine from "../../components/SVGLine/svg-line"
import { LandingContainer } from "./landing.styles"

const LandingPage = () => {
    const [section, setSection] = useState<Routes>(Routes.GAME)
    const [requestModalShown, setRequestModalShown] = useState(false)



    const toggleRequest = () => {
        setRequestModalShown(c => !c)
    }



    const changeSection = (section: Routes, navigate?: boolean) => {
        



        if (navigate) {
            const headerHeight = document.querySelector('#app-header')?.getBoundingClientRect().height ?? 0

            const topOffset = (((document.querySelector(`#${section}`) as any))?.getBoundingClientRect().top ?? 0) + window.scrollY

            
            window.scrollTo({
                top: topOffset - headerHeight,
                left: 0,
                behavior: 'smooth'
            })


        } else {
            setSection(section)
        }
    }


    return (
        <LandingContainer>
            <SVGLine />
            <Header
                selectedSection={section}
                onSelectSection={changeSection}
                showRequestModal={() => {
                    toggleRequest()
                }}

            />
            <MainSection sendRequest={() => {
                toggleRequest()

            }} 
            onSelectSection={changeSection}
            />
            <NavigationSection 
            onSelectSection={changeSection}
            />
            <AdvantagesSection 
            sendRequest={() => {
                toggleRequest()

            }} 
             onSelectSection={changeSection}
             />
            <StatsSection />
            <MechanicsSection 
             onSelectSection={changeSection}
            />
            <GoalsSection 
             onSelectSection={changeSection}
            />
            <AboutSection
             onSelectSection={changeSection}
            sendRequest={() => {
                toggleRequest()
            }} />

            <InfoSection />
            <AwardsSection />
            <Footer
                selectedSection={section}
                onSelectSection={changeSection}
                showRequestModal={() => {
                    toggleRequest()
                }}

            />
            <RequestModal
            open={requestModalShown}
            onClose={toggleRequest}
            />
        </LandingContainer>
    )
}




export default LandingPage