import { useCallback, useEffect } from "react";
import { useToast } from "@chakra-ui/react";

interface ShowToastProps {
  message: string | undefined;
  clearMessage: any;
  status: "error" | "info" | "warning" | "success";
}



export const useCustomToast = () => {
  const toast = useToast();
  const showToast = useCallback(
    (
      message: string,
      status: "error" | "info" | "warning" | "success",
      title: string
    ) => {
      toast({
        title: title ? title : "Ошибка",
        description: message,
        position: "top-right",
        status: status ? status : "error",
        duration: 8000,
        isClosable: true,
      });
    },
    [toast]
  );
  return showToast;
};
