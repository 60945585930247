import { Routes } from "../Header/header";
import { Line, NavigationLinesContainer } from "./navigationLines.styles";

interface HeaderProps {

    selectedSection: Routes,
    
    color: string,
    top: string
}


const NavigationLines:React.FC<HeaderProps> = ({
    color,

    selectedSection,
    top
}) => {
    return (
        <NavigationLinesContainer top={top}>
            {
                Object.values(Routes).map((value, index) => {
                    return (
                        <Line key={index} active={value === selectedSection} color={color}/>
                    )
                })
            }
        </NavigationLinesContainer>
    )
}



export default NavigationLines