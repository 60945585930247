import styled from "styled-components";





export const FormTitle = styled.h5`
font-weight: 600;
font-size: 1.875rem;
line-height: 120%;
text-align: center;

color: #000000;
margin-bottom: 2.25rem;
align-self: center;



@media(max-width: 1000px) {
 font-size: 1.375rem;
 margin-bottom: 1.375rem;
}
`



export const FormSubtitle = styled.h6`
font-weight: normal;
font-size: 1rem;
line-height: 150%;
/* identical to box height, or 24px */
margin-bottom: 0.75rem;

color: #000000;
text-align: center;
align-self: center;


`




export const FormContainer = styled.form`
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
*:focus {
    outline: none;
}
`


interface ErrorProps {
    error?: boolean
}



export const TextArea = styled.textarea<ErrorProps>`
border: ${props => props.error ? ` 2px solid ${props.theme.palette.colors.red}` : ` 2px solid #000000`};
box-sizing: border-box;
border-radius: 0.625rem;
height: 15.375rem;
width: 100%;
resize: none;
margin-bottom: 2rem;
font-style: normal;
font-weight: 600;
font-size: 1rem;
padding: 1rem;
line-height: 120%;
color: ${props => props.theme.palette.colors.black};
`



export const Textinput = styled.input<ErrorProps>`
border: ${props => props.error ? ` 2px solid ${props.theme.palette.colors.red}` : ` 2px solid #000000`};
box-sizing: border-box;
border-radius: 2rem;
height: 3.6rem;
width: 100%;
max-width: 18.75rem;
resize: none;
margin-bottom: 2rem;
font-style: normal;
font-weight: 600;
font-size: 1rem;
padding:  0.5 1rem;
line-height: 120%;
color: ${props => props.theme.palette.colors.black};
text-align: center;
&::placeholder {
    font-size: 0.875rem;
    text-transform: uppercase;
    color: ${props => props.theme.palette.colors.black};
    font-weight: 600;
}


@media(max-width: 1000px) {
    max-width: 100%;
}
`

export const RequestButton = styled.button`
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
width: 100%;
max-width: 18.75rem;
height: 3.6rem;
background: ${props => props.theme.palette.colors.red};
cursor: pointer;
color: ${props => props.theme.palette.colors.white};
font-weight: 600;
font-size: 0.875rem;
line-height: 120%;
/* identical to box height */
border-radius: 2rem;
text-transform: uppercase;
align-self: center;
position: relative;
@media(max-width: 1000px) {
    max-width: 100%;
}


`


export const LoaderContainer = styled.div`
position: absolute;
left: 1.5rem;
`