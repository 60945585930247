import { useEffect, useState } from "react"
import { DescriptionText, SectionContainer, SectionWrapper, StatsRow, TabloRow } from "./statsSection.styles"
import Tablo from "./Tablo/tablo"
import { useInView } from 'react-intersection-observer'
import { useWindowDimensions } from "../../hooks/dimensions"
import * as Device from 'react-device-detect'

const StatsSection = () => {


    return (
        <SectionWrapper>
            {/* <button onClick={() => setAnimate(true)}>Click</button> */}
            <SectionContainer id='stats'>
                <StatsContent/>
            </SectionContainer>
        </SectionWrapper>
    )
}



export default StatsSection





const StatsContent = () => {
    const [animate, setAnimate] = useState(false)
    const [secondAnimate, setSecondAnimate] = useState(false)
    const [thirdAnimate, setthirdAnimate] = useState(false)
    const [fourthAnimate, setfourthAnimate] = useState(false)
    const [fifthAnimate, setfifthAnimate] = useState(false)
    const {width} = useWindowDimensions()

    const { ref: firstRowRef, inView: firstRowInView } = useInView({threshold: 0.9, triggerOnce: true})
    const { ref: secondRowRef, inView: secondRowInView } = useInView({threshold: 0.9, triggerOnce: true})
    const { ref: thirdRowRef, inView: thirdRowInView } = useInView({threshold: 0.9, triggerOnce: true})
    const { ref: fourthRowRef, inView: fourthRowInView } = useInView({threshold: 0.9, triggerOnce: true})
    const { ref: fifthRowRef, inView: fifthRowInView } = useInView({threshold: 0.9, triggerOnce: true})


    useEffect(() => {
        if (firstRowInView) {
            setAnimate(true)
        }
    }, [firstRowInView])


    useEffect(() => {
        if (secondRowInView) {
            setSecondAnimate(true)
        }
    }, [secondRowInView])

    useEffect(() => {
        if (thirdRowInView) {
            setthirdAnimate(true)
        }
    }, [thirdRowInView])


    useEffect(() => {
        if (fourthRowInView) {
            setfourthAnimate(true)
        }
    }, [fourthRowInView])
    useEffect(() => {
        if (fifthRowInView) {
            setfifthAnimate(true)
        }
    }, [fifthRowInView])
    


    return (
        <>
                <StatsRow>
                    <TabloRow ref={firstRowRef}>
                        <Tablo delay={0} list={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].reverse()} animate={animate} empty />
                        <Tablo delay={50} list={[8, 9, 0, 1, 9, 8, 7, 6, 5, 4, 3, 2]} animate={animate} />
                        <Tablo delay={20} list={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].reverse()} animate={animate} />
                        <Tablo delay={80} list={[1, 9, 8, 7, 6, 5, 4, 3, 2]} animate={animate} />
                        <Tablo delay={0} list={[0, 1, 2, 3, 4, 5, 6, 7].reverse()} animate={animate} />

                    </TabloRow>
                    <DescriptionText>
                        - Проводим Онлайн-тимбилдинг с марта <strong>2020</strong> года
                    </DescriptionText>
                </StatsRow>
                <StatsRow>
                    <TabloRow ref={secondRowRef}>
                        <Tablo delay={0} list={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].reverse()} animate={secondAnimate} empty />
                        <Tablo delay={100} list={[7, 6, 5, 4, 3, 2]} animate={secondAnimate} />
                        <Tablo delay={50} list={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].reverse()} animate={secondAnimate} />
                        <Tablo delay={0} list={[9, 0, 1]} animate={secondAnimate} />
                        <Tablo delay={20} list={[3, 2, 1, 0, 9, 8, 7]} animate={secondAnimate} />


                    </TabloRow>
                    <DescriptionText>
                        - Собственная онлайн-платформа с <strong>2017</strong> года
                    </DescriptionText>
                </StatsRow>
                <StatsRow>
                    <TabloRow ref={thirdRowRef}>
                        <Tablo delay={0} list={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].reverse()} animate={thirdAnimate} empty />
                        <Tablo delay={20} list={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].reverse()} animate={thirdAnimate} empty />
                        <Tablo delay={0} list={[0, 1, 9, 8, 7, 6, 5, 4, 3, 2]} animate={thirdAnimate} />
                        <Tablo delay={80} list={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0].reverse()} animate={thirdAnimate} />
                        <Tablo delay={100} list={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0].reverse()} animate={thirdAnimate} />


                    </TabloRow>
                    <DescriptionText>
                        - Провели более <strong>200</strong> игр
                    </DescriptionText>
                </StatsRow>
                <StatsRow>
                    <TabloRow ref={fourthRowRef}>
                        <Tablo delay={0} list={[9, 8, 7, 6, 5, 4, 3, 2, 1]} animate={fourthAnimate} />
                        <Tablo delay={0} list={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].reverse()} animate={fourthAnimate} />
                        <Tablo delay={30} list={[0, 1, 2, 3, 4, 5, 6, 7, 8,].reverse()} animate={fourthAnimate} />
                        <Tablo delay={90} list={[0, 1, 2, 3, 4,].reverse()} animate={fourthAnimate} />
                        <Tablo delay={50} list={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0].reverse()} animate={fourthAnimate} />

                    </TabloRow>
                    <DescriptionText>
                        - Более <strong>10 000</strong> участников
                    </DescriptionText>
                </StatsRow>
                <StatsRow>
                    <TabloRow ref={fifthRowRef}>
                        <Tablo delay={0} list={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].reverse()} animate={fifthAnimate} empty />
                        <Tablo delay={40} list={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].reverse()} animate={fifthAnimate} empty />
                        <Tablo delay={65} list={[9, 0, 1, 9, 8, 7, 6, 5, 4, 3, 2, 1]} animate={fifthAnimate} />
                        <Tablo delay={20} list={[9, 0, 1, 9, 8]} animate={fifthAnimate} />
                        <Tablo delay={0} list={[9, 0, 1, 9, 8, 7, 6, 5, 4, 3]} animate={fifthAnimate} />

                    </TabloRow>
                    <DescriptionText>
                        - В наши игры сыграли{(width < 1400 && width>1000) && <br/>} <strong>183</strong> компании
                    </DescriptionText>
                </StatsRow>
        </>
    )
}