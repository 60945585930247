import styled from 'styled-components'




export const MainSectionWrapper = styled.section`
width: 100%;
min-height: 100vh;
background: ${props => props.theme.palette.colors.red};
display: flex;
flex-direction: column;
align-items: center;
position: relative;
`
export const MainSectionContainer = styled.div`
width: 100%;

flex-direction: column;
display: flex;
align-items: center;

`

export const DescriptionText = styled.h6`
color: ${props => props.theme.palette.colors.white};
font-size: 1.8125rem;

font-weight: 700;
max-width: 85rem;
text-align: center;
margin-bottom: 4.5rem;
@media(max-width: 1000px) {
    max-width: 18.125rem;
    font-weight: 400;
    font-size: 0.75rem;
    margin-top: calc(2rem + 52px);
    margin-bottom: 2.625rem;
} 
`


export const GamesContainer = styled.div`

width: 100%;
display: flex;
flex-direction: row;
align-items: flex-start;


@media(max-width: 1000px) {


    flex-direction: column;
    
}
`


export const GameInnerWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
position: relative;
overflow-x: hidden;
height: 100%;
max-width: 54rem;
width: 100%;

`
export const GameContainer = styled.div`
width: 50%;
display: flex;
flex-direction: column;
position: relative;
overflow-x: hidden;
height: 100%;
&:first-of-type {
    align-items: flex-end;
    padding-left: 5.75rem;
    ${GameInnerWrapper} {
        align-items: flex-start;
    }
    
}

&:last-of-type {
    align-items: flex-start;
    padding-left: 5.875rem;
    padding-right: 3.125rem;
    ${GameInnerWrapper} {
        align-items: flex-start;
    }
}


@media(max-width: 1000px) {
    width: 100%;
    &:first-of-type {
        margin-top: 3.5rem;
    align-items: flex-end;
    padding-left: 1rem;
    ${GameInnerWrapper} {
        align-items: flex-start;
    }
    
}

&:last-of-type {
    align-items: flex-start;
    padding-left: 0rem;
    padding-right: 1rem;
    ${GameInnerWrapper} {
        align-items: flex-end;
    }
}

}
`





export const GameTitle = styled.h1`
color: ${props => props.theme.palette.colors.white};
font-size: 8rem;
font-weight: bold;
line-height: 120%;
margin-bottom: 1rem;



@media(max-width: 1400px) {
    font-size: 6rem;
}
@media(max-width: 1000px) {
    font-size: 3rem;
    margin-bottom: 0.625rem;
}
`

export const GameSubtitle = styled.h6`
color: ${props => props.theme.palette.colors.white};
font-size: 1.875rem;
font-weight: 600;
line-height: 120%;
margin-bottom: 2rem;
height: 8.125rem;
@media(max-width: 1400px) {
    font-size: 1.5rem;
}
@media(max-width: 1000px) {
    font-size: 1.25rem;
    height: auto;


}
`


export const DetailsButton = styled.button`
background: ${props => props.theme.palette.colors.white};
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
color: ${props => props.theme.palette.colors.red};
font-size: 0.875rem;
font-weight: 600;
line-height: 120%;
cursor: pointer;
width: 10.3rem;
height: 3.5rem;
border-radius: 2rem;
z-index: 1;
`


export const ImageContainer = styled.div`
align-self: flex-end;
position: relative;
bottom: 5rem;



@media(max-width: 1000px) {
    bottom: 1rem;
}
`



export const OverlayContainer = styled.div`
position: absolute;
width: 100%;
left:0;
bottom: 0;
`



export const VideosContainer = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
width: 100%;
background: ${props => props.theme.palette.colors.black};
padding-bottom: 6rem;
.BrainhubCarousel__trackContainer,  .BrainhubCarousel, .BrainhubCarousel__container  {
    overflow: visible;
}


@media(max-width: 1000px) {
    justify-content: flex-start;
    padding-bottom: 0.5rem;
}
`


export const VideosWrapper = styled.div`
width: 18.5rem;
overflow: visible;
`


export const VideoOuterWrapper = styled.div`
padding-left: 1rem;
width: 18.5rem;
`


interface VideoProps {
    preview?: string,
    red?: boolean
}

export const VideoWrapper = styled.div<VideoProps>`
width: 42.25rem;
height: 22rem;
background: ${props => props.preview ? props.theme.palette.colors.black : '#E5E5E5'};
border-radius: 8px;
overflow: hidden;
position: relative;
.react-player__preview {
    background-image: ${props => `${props.preview}!important` ?? 'none!important'} ;
}

video {
    border-radius: 8px;
    overflow: hidden;
}

#avatar-play {
    >path {
        fill: ${props => props.red ? '#D5333E' : '#fff'};
        opacity: 1;

    }
}


@media(max-width: 1400px) {
    width: 30rem;
    height: 15.6rem;
}


@media(max-width: 1000px) {
    width: 17.5rem;
    height: 9.125rem;
    
}
`



export const PlaceHolderText = styled.div`
display: flex;
height: 100%;
width: 100%;
justify-content: center;
align-items: center;
color: ${props => props.theme.palette.colors.black};
font-size: 4rem;
text-align: center;
text-transform: uppercase;
font-weight: bold;


@media(max-width: 1000px) {
    font-size: 2rem;
}
`

export const Divider = styled.div`
position: relative;
overflow: visible;
>div {
    position: absolute;

    width: 2px;
    border-radius: 8px;
    background: ${props => props.theme.palette.colors.white};
    height: 68.8125rem;
    bottom: -20rem;
}
`


export const Contacts = styled.div`
    align-self: flex-end;
    
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 10rem;
    cursor: pointer;
    color: ${props => props.theme.palette.colors.white};
    font-weight: 600;
    margin-bottom: 2rem;
    margin-right: 3.125rem;
    >a {
        display: flex;
        text-transform: lowercase;
        font-size: 1.25rem;
        text-align: right;
        line-height: 140%;

    }
`



export const DotsContainer = styled.div`
width: 100%;
display: flex;
justify-content: center;
align-items: center;
background: ${props => props.theme.palette.colors.black};
padding-bottom: 2rem;
`


export const TitleWrapper = styled.div`
background-color: ${props => props.theme.palette.colors.black};
width: 100%;
padding-bottom: 6rem;
@media(max-width: 1000px) {
    padding-bottom: 2rem;
}
`