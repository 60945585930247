import { isSafari } from 'react-device-detect';
import styled from 'styled-components'




export const AdvantagesSectionWrapper = styled.div`
width: 100%;
display: flex;
flex-direction:row;
justify-content: center;
position: relative;
`



export const AdvantagesSectionContainer = styled.div`
display: flex;
flex-direction: column;
margin-top: 1rem;
width: 100%;
max-width: 120rem;

@media(max-width: 1000px) {
    margin-top: 0rem;
    }
`



export const AdvantagesSectionTitle = styled.div`
    margin-right: 14.5%;
    display: flex;
    align-items: center;
    align-self: flex-end;
    >div {
        height: 2px;
        width: 4.675rem;
        border-radius: 0.625rem;
        background: ${props => props.theme.palette.colors.red};
    }
    >h5 {
        margin-right: 1.5rem;
        color: ${props => props.theme.palette.colors.black};
        font-weight: bold;
        font-size: 4rem;
    }

    @media(max-width: 1000px) {
        margin-right: 0.25rem;
        >div {
            width: 0.5rem;
        }
        >h5 {
            font-size: 1.25rem;
            margin-right: 0.25rem;
        }
    }
`


interface InfoCircleProps {

    radius: string;
    zIndex: number
}


export const InfoCircle = styled.div<InfoCircleProps>`

    width: ${props => props.radius};
    height: ${props => props.radius};
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(77.12deg, rgba(255, 255, 255, 0.8) 32.27%, rgba(255, 255, 255, 0.5) 85.91%);
    box-shadow: 0px 45.5px 78px rgba(200, 50, 45, 0.1);
    
    backdrop-filter: ${props => isSafari ? `none` : 'blur(32.5px)'};
    z-index: ${props => props.zIndex};


    @media(max-width: 1000px) {
        background: linear-gradient(77.12deg, rgba(255, 255, 255, 0.8) 32.27%, rgba(255, 255, 255, 0.5) 85.91%);
box-shadow: 0px 20px 31px rgba(200, 50, 45, 0.1);
    }
`


export const CirclesContainer = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
margin-top: 17rem;
position: relative;
>div:last-of-type {
    position: absolute;
    bottom: 5rem;
}


@media(max-width: 1000px) {
    flex-direction: column;
    margin-top: 1rem;
    align-items: flex-start;
    left: -10px;

    >div:last-of-type {
    position: absolute;
    left: 35%;
    bottom: inherit;
}
}
`



export const InfoCircleContent = styled.div`
display: flex;
flex-direction: column;
`


export const InfoCircleText = styled.div`
font-size: 1.25rem;
color: ${props => props.theme.palette.colors.black};
font-weight: 500;
margin-top: 4rem;
text-align: center;

@media(max-width: 1000px) {
    /* display: inline-block; */
    font-size: 0.75rem;
    margin-top: 0.875rem;
}
`

export const InfoCircleHighlight = styled(InfoCircleText)`
color: ${props => props.theme.palette.colors.red};
font-weight: 700;
margin-top: 0;
`


export const InfoCircleMobileText = styled.span`
font-size: 1.25rem;
color: ${props => props.theme.palette.colors.black};
font-weight: 500;
margin-top: 4rem;
text-align: center;
margin-left: 4px;
@media(max-width: 1000px) {
    
    font-size: 0.75rem;
    margin-top: 0.875rem;
}
`

export const InfoCircleMobileHighlight = styled.strong`
font-size: 1.25rem;
color: ${props => props.theme.palette.colors.black};
font-weight: 500;
margin-top: 4rem;
text-align: center;
@media(max-width: 1000px) {
    
    font-size: 0.75rem;

}

color: ${props => props.theme.palette.colors.red};
font-weight: 700;
margin-top: 0;
`



export const RequestButton = styled.button`
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
width: 13.4375rem;
height: 3.4375rem;
background: ${props => props.theme.palette.colors.red};
cursor: pointer;
color: ${props => props.theme.palette.colors.white};
font-weight: 600;
font-size: 0.875rem;
line-height: 120%;
/* identical to box height */
border-radius: 2rem;
text-transform: uppercase;
align-self: center;
margin-top: 1rem;



@media(max-width: 1000px) {

    margin-top: 3.5rem;
}

`