import styled from 'styled-components'




export const SectionWrapper = styled.section`
width: 100%;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
padding: 0rem 9.813rem;
z-index: 5;


@media(max-width: 1400px) {

    padding-left: 6rem;
    padding-right: 6rem;
}

@media(max-width: 1000px) {

padding-left: 1rem;
padding-right: 0;
}
`



export const SectionContainer = styled.div`
height: 55.375rem;
width: 100%;
max-width: 120rem;
margin-top: 6.813rem;
display: flex;
flex-direction: column;
background: ${props => `no-repeat 100% 100%/contain url(/statsbg.png), ${props.theme.palette.colors.red}`};
border-radius: 3.75rem;
padding: 5.25rem 9.75rem ;
box-shadow: 0.375rem 0.5rem 1.875rem rgba(0, 0, 0, 0.25);


@media(max-width: 1400px) {

padding-left: 6rem;
}
@media(max-width: 1000px) {
    margin-top: 3.125rem;
    height: auto;
    padding: 2.5rem 2.5rem  10.675rem 1rem;
    border-radius: 1.25rem 0rem 0rem 1.25rem;
    background-color: ${props => props.theme.palette.colors.red};
    background: ${props => `no-repeat -2% 101%/90% url(/tablobg.png), linear-gradient(to right, ${props.theme.palette.colors.red}, ${props.theme.palette.colors.red})`};
}
`



export const StatsRow = styled.div`
display: flex;
flex-direction: row;
align-items: center;
&:not(:last-of-type) {
    margin-bottom: 2.5rem;
}


@media(max-width: 1000px) {
    flex-direction: column;
    &:not(:last-of-type) {
    margin-bottom: 1.75rem;
}
}
`


export const TabloRow = styled.div`
display: flex;
flex-direction: row;
align-items: center;
`



export const DescriptionText = styled.span`
margin-left: 3rem;
font-size: 1.5rem;
font-weight: 500;
color: ${props => props.theme.palette.colors.white};
>strong {
    font-weight: 700;
}

@media(max-width: 1000px) {
    max-width: 15rem;
    margin-top: 0.75rem;
    font-size: 0.875rem;
    margin-left: 0;
    text-align: center;
}


`