import styled from 'styled-components' 



export const SVGContainer = styled.svg`
position: absolute;
>path {
    stroke-width: 0.25rem;
    stroke: ${ props => props.theme.palette.colors.red}

}
stroke-dasharray:  12 12;
`