import { GoalsBody, GoalsColumn, GoalsDivider, GoalsGrid, GoalsItem, GoalsSectionContainer, GoalsSectionTitle, GoalsSectionWrapper, PostTitle } from "./goalsSection.styles"

import { ReactComponent as CommunitySVG } from '../../assets/community.svg'
import { ReactComponent as StressSVG } from '../../assets/stress.svg'
import { ReactComponent as OnlineSVG } from '../../assets/online.svg'
import { ReactComponent as NetworkSVG } from '../../assets/network.svg'
import { Routes } from "../Header/header"
import { useEffect } from "react"
import { useInView } from "react-intersection-observer"
import NavigationLines from "../NavigationLines/navigationLines"
import { scaleElementToWidth } from "../../utils/utils"
import { useWindowDimensions } from "../../hooks/dimensions"


const GoalsSection = ({ onSelectSection }: { onSelectSection: (route: Routes) => void }) => {

    const { width } = useWindowDimensions()


    const { inView, ref } = useInView({
        threshold: 0.3
    })

    useEffect(() => {
        if (inView) {
            onSelectSection(Routes.GOALS)
        }
    }, [inView])


    return (
        <GoalsSectionWrapper id='Задачи' ref={ref}>
            {
                width > 1000 &&
                <NavigationLines
                    selectedSection={Routes.GOALS}
                    color="#D5333E"
                    top="50%"


                />
            }
            <GoalsSectionContainer>
                <GoalsSectionTitle>
                    <div />
                    <h5>
                        С какими задачами<br /> МЫ работаем?
                    </h5>

                </GoalsSectionTitle>
                {
                    width > 1000 ?
                        <GoalsGrid>
                            <GoalsColumn>
                                <GoalsItem>
                                    <CommunitySVG width={scaleElementToWidth(width, 173)} height={scaleElementToWidth(width, 154)} />
                                    <GoalsBody
                                        style={{
                                            marginTop: '3rem'

                                        }}
                                    >
                                        <div style={{
                                            left: '-2.5rem',
                                        }} />
                                        <span>
                                        <strong>Командообразование</strong> – от сплочения до проработки отдельных soft-skills и компетенций.

                                        </span>
                                    </GoalsBody>
                                </GoalsItem>
                                <GoalsItem>
                                    <OnlineSVG width={scaleElementToWidth(width, 152)} height={scaleElementToWidth(width, 131)} />
                                    <GoalsBody
                                        style={{
                                            marginTop: '2rem'

                                        }}
                                    >
                                        <div style={{
                                            left: '-1.5em',
                                        }} />
                                        <span>
                                        Игровое онлайн {' '}                                        <strong>
                                        обучение
                                            </strong> по любым темам – от схем коммуникации при удалённой работе до основ безопасной жизнедеятельности.

                                        </span>
                                    </GoalsBody>
                                </GoalsItem>
                            </GoalsColumn>
                            <GoalsDivider />
                            <GoalsColumn>
                                <GoalsItem>
                                    <StressSVG width={scaleElementToWidth(width, 126)} height={scaleElementToWidth(width, 162)} />
                                    <GoalsBody
                                        style={{
                                            marginTop: '2.5rem'

                                        }}
                                    >
                                        <div style={{
                                            left: '-2.5rem',
                                        }} />
                                        <span>
                                        Помощь в борьбе со  <strong>стрессом</strong> при удалённой работе.

                                        </span>
                                    </GoalsBody>
                                </GoalsItem>
                                <GoalsItem>
                                    <NetworkSVG
                                        width={scaleElementToWidth(width, 139)} height={scaleElementToWidth(width, 133)}
                                    />
                                    <GoalsBody
                                        style={{

                                            marginTop: '1.875rem'
                                        }}
                                    >
                                        <div style={{
                                            left: '0rem',
                                        }} />
                                        <span>
                                        <strong>Отработка</strong> схем взаимодействия, коммуникаций и передачи информации без потерь в качестве и скорости.

                                        </span>
                                    </GoalsBody>
                                </GoalsItem>
                            </GoalsColumn>
                        </GoalsGrid>
                        :

                        <GoalsColumn>
                            <GoalsItem>
                                <CommunitySVG width={scaleElementToWidth(width, 173)} height={scaleElementToWidth(width, 154)} />
                                <GoalsBody
                                    style={{
                                        marginTop: '3rem'

                                    }}
                                >
                                    <div style={{
                                        left: '-0.625rem',
                                    }} />
                                    <span>
                                        <strong>Командообразование</strong> – от сплочения до проработки отдельных soft-skills и компетенций.
                                    </span>
                                </GoalsBody>
                            </GoalsItem>
                            <GoalsItem>
                                <StressSVG width={scaleElementToWidth(width, 126)} height={scaleElementToWidth(width, 162)} />
                                <GoalsBody
                                    style={{
                                        marginTop: '2.5rem'

                                    }}
                                >
                                    <div style={{
                                        left: '-0.5rem',
                                    }} />
                                    <span>
                                    Помощь в борьбе со <strong>стрессом</strong> при удалённой работе.

                                    </span>
                                </GoalsBody>
                            </GoalsItem>
                            <GoalsItem>
                                <OnlineSVG width={scaleElementToWidth(width, 152)} height={scaleElementToWidth(width, 131)} />
                                <GoalsBody
                                    style={{
                                        marginTop: '2rem'

                                    }}
                                >
                                    <div style={{
                                        left: '-2.25rem',
                                    }} />
                                    <span>
                                    Игровое онлайн {' '}<strong>обучение</strong> по любым темам – от схем коммуникации при удалённой работе до основ безопасной жизнедеятельности.

                                    </span>
                                </GoalsBody>
                            </GoalsItem>
                            <GoalsItem>
                                <NetworkSVG
                                    width={scaleElementToWidth(width, 139)} height={scaleElementToWidth(width, 133)}
                                />
                                <GoalsBody
                                    style={{

                                        marginTop: '1.875rem'
                                    }}
                                >
                                    <div style={{
                                        left: '1.875rem',
                                    }} />
                                    <span>

                                    <strong>Отработка</strong> схем взаимодействия, коммуникаций и передачи информации без потерь в качестве и скорости.
                                    </span>
                                </GoalsBody>
                            </GoalsItem>
                        </GoalsColumn>
                }
                <PostTitle>
                    Те задачи,{width<1001 && <br/>} которые вы<br /> нам поставите )))
                </PostTitle>
            </GoalsSectionContainer>
        </GoalsSectionWrapper>
    )
}



export default GoalsSection