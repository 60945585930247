import styled from "styled-components";



interface LinesProps {
    top: string,
    
}

export const NavigationLinesContainer = styled.div<LinesProps>`
position: absolute;


right: 3rem;
top: ${props => props.top};
display: flex;
flex-direction: column;
align-items: flex-end;

@media (max-width: 1400px) {
    right: 1rem;
}
`

interface LineProps {
    active: boolean,
    color: string
}

export const Line = styled.div<LineProps>`
background: ${props => props.color};
width: ${props => props.active ? '3.125rem' : '1.25rem'};
height: 1px;


&:not(:last-of-type) {
    margin-bottom: 1rem;
}
@media(max-width: 1400px) {
    width: ${props => props.active ? '2rem' : '1rem'};
}
`

