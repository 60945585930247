import styled from "styled-components";



export const ContentContainer = styled.div`
width: 100%;
background-color: ${props => props.theme.palette.colors.white};
display: flex;
flex-direction: column;
padding: 4rem 0rem 3.5rem 9.5rem;
position: relative;
border-radius: 3rem;
.BrainhubCarousel__trackContainer,  .BrainhubCarousel, .BrainhubCarousel__container  {
    overflow: visible;
}


@media(max-width: 1000px) {
    min-height: 100vh;
    border-radius: 0rem;
  
    padding: 2.25rem 0rem 3.5rem 0rem;
}
`



export const CloseContainer = styled.div`
position: absolute;
top: 3.5rem;
right: 3.5rem;
cursor: pointer;


@media(max-width: 1000px) {
    top: 1.5rem;
    right: 1.5rem;
}
`

export const TitleContainer = styled.h1`
font-weight: bold;
font-size: 8.125rem;
color: ${props => props.theme.palette.colors.black};
line-height: 120%;
margin-bottom: 1.25rem;



@media(max-width: 1000px) {
    font-size: 2.5rem;
    padding: 0rem 1rem;
}
`



export const Line = styled.div`
width: 1px;
height: 4.5rem;
background-color: ${props => props.theme.palette.colors.red};
position: absolute;
top: 0;
left: 12.5rem;
`


export const DescriptionContainer = styled.div`

`


export const DescriptionText = styled.span`

color: ${props => props.theme.palette.colors.black};
font-weight: 400;
font-size: 1rem;
line-height: 150%;
max-width: 44rem;
display: block;
margin-bottom: 1rem;


@media(max-width: 1000px) {
    font-size: 0.875rem;
    
}
`


export const DescriptionHightlight = styled.span`
color: ${props => props.theme.palette.colors.black};
font-weight: 600;
font-size: 1rem;
@media(max-width: 1000px) {
    font-size: 0.875rem;
    
}
`

export const DescriptionRedHightlight = styled.span`
color: ${props => props.theme.palette.colors.red};
font-weight: 700;
font-size: 1rem;
text-transform: uppercase;
@media(max-width: 1000px) {
    font-size: 0.875rem;
}
`


export const SectionContainer = styled.div`
position: relative;
@media(max-width: 1000px) {
    
    padding: 0rem 1rem;
}
`


export const SVGContainer = styled.div`
position: absolute;
top: 0;
right: calc(100% + 2rem);

@media(max-width: 1000px) {
    position: initial;
    margin-bottom: 1rem;
}
`



export const CarousleWrapper = styled.div`
width: 100%;

position: relative;
display: flex;
align-items: center;
margin-top: 2rem;
`

export const CarousleInnerWrapper = styled.div`
width: 100%;
overflow: hidden;
position: relative;
display: flex;
align-items: center;
`





export const ArrowRight = styled.div`
cursor: pointer;
position: absolute;
right: 4.5rem;
width: 3rem;
height: 3rem;
border-radius: 50%;
background: rgba(36, 39, 56, 0.5);
display: flex;
justify-content: center;
align-items: center;
z-index: 5;
`

export const ArrowLeft = styled.div`
cursor: pointer;
position: absolute;
left: -5rem;
>svg {
    transform: rotate(180deg);
}
width: 3rem;
height: 3rem;
border-radius: 50%;
background: rgba(36, 39, 56, 0.5);
display: flex;
justify-content: center;
align-items: center;
z-index: 5;

`


export const CarouselContainer = styled.div`
width: 100%;

width: 41.875rem;
@media(max-width: 1000px) {
    padding-left: 1rem;
    width: calc(85vw + 1rem);
    
}
`


export const SlideContainer = styled.div`
    padding: 1rem 1rem 1rem 0rem;
    img {
        box-shadow: 0px 5px 10px rgba(0,0,0,0.2);
        overflow: hidden;
    border-radius: 0.75rem;
    width: 41.875rem;
    /* overflow: hidden; */
}



@media(max-width: 1000px) {
    padding: 1rem 0.5rem 1rem 0rem;
    >img {

        width: 85vw;
        border-radius: 0.25rem;
        box-shadow: 0px 2px 5px rgba(0,0,0,0.1);
    }
}
`

interface DotsProps {
    active: boolean
}




export const Dot = styled.div<DotsProps>`
width: 6px;
height: 6px;
border-radius: 4px;
background: ${props => props.active ? props.theme.palette.colors.black : '#c4c4c4'};
`



export const RedDot = styled.div<DotsProps>`
width: 6px;
height: 6px;
border-radius: 4px;
background: ${props => props.active ? props.theme.palette.colors.red : '#c4c4c4'};
`






export const ButtonsContainer = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: flex-start;
margin-top: 2.5rem;

@media(max-width: 1000px) {
    flex-direction: column-reverse;
    align-items: center;
    padding: 0rem 1rem;
}
`


export const RequestButton = styled.button`
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
width: 13.4375rem;
height: 3.4375rem;
background: ${props => props.theme.palette.colors.red};
cursor: pointer;
color: ${props => props.theme.palette.colors.white};
font-weight: 600;
font-size: 0.875rem;
line-height: 120%;
/* identical to box height */
border-radius: 2rem;
text-transform: uppercase;
align-self: center;

`


export const DownloadButton = styled.a`
text-align: start;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
width: 20.4375rem;
height: 3.4375rem;
background: ${props => props.theme.palette.colors.black};
cursor: pointer;
color: ${props => props.theme.palette.colors.white};
font-weight: 600;
font-size: 0.875rem;
line-height: 120%;
/* identical to box height */
border-radius: 2rem;
text-transform: uppercase;
align-self: center;
>svg {
    margin-right: 1.5rem;
}

margin-right: 8.375rem;

@media(max-width: 1000px) {
    width: 100%;
    margin-right: 0;
    margin-top: 2.25rem;
}
`
