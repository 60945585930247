import { isSafari } from 'react-device-detect'
import styled from 'styled-components'


export const NumbersContainer = styled.div`
/* overflow: hidden; */
position: relative;
&:not(:last-of-type) {
    margin-right: 1rem;
}
width: calc(4.875rem*0.86);
    height: calc(6.875rem*0.86);
transform-style: preserve-3d;
perspective: 1000px;
box-shadow: 0.4rem 0.4rem 0.5rem rgba(0,0,0,0.25);
>img:first-of-type {
    display: none;
    position: absolute;
    top: -0.2rem;
    left: -0.2rem;
    z-index: -2;
    width: calc(4.875rem*1.05);
    height: calc(6.875rem*1.05);
}
>img:not(:first-of-type) {
    z-index: -1;
    width: calc(4.875rem*0.86);
    height: calc(6.875rem*0.86);
}

@media(max-width: 1000px) {
    width: calc(4.875rem*0.50);
    height: calc(6.875rem*0.50);
    
    &:not(:last-of-type) {
    margin-right: 0.75rem;
}
    >img:first-of-type {
    display: none;
    position: absolute;
    top: -0.2rem;
    left: -0.2rem;
    z-index: -2;
    width: calc(4.875rem*(1.05*0.5/0.86));
    height: calc(6.875rem*(1.05*0.5/0.86));
}
>img:not(:first-of-type) {
    z-index: -1;
    width: calc(4.875rem*0.5);
    height: calc(6.875rem*0.5);
}
}
`

export const NumberContainer = styled.div`
transform-style: preserve-3d;
perspective: 1000px;
position: absolute;
top: 0;
left: 0;
width: calc(4.875rem*0.86);
height: calc(6.875rem*0.86);

@media(max-width: 1000px) {
    width: calc(4.875rem*0.50);
    height: calc(6.875rem*0.50);
}
`



interface RotationProps {
    rotation: boolean,
    duration: number,
    delay: number

}

export const TopSide = styled.div<RotationProps>`
display: flex;
position: absolute;
transform-style: preserve-3d;
top: 0;
left: 0;
transform: ${props => props.rotation ? 'rotateX(-180deg)' : 'rotateX(0deg)'};
transition-delay: ${props => `${props.delay}ms`};
transition-duration: ${props => `${props.duration}ms`};
transform-origin: center bottom;
transition-timing-function: ease-out;
height: calc(3.375rem*0.86);
@media(max-width: 1000px) {
    
    height: calc(3.375rem*0.50);
}
/* overflow: hidden; */
`


export const BottomSide = styled.div<RotationProps>`
display: flex;
align-items: flex-end;
position: absolute;
transform-style: preserve-3d;
bottom: 0;
left: 0;
height: calc(3.375rem*0.86);
width: 100%;
height: 100%;
@media(max-width: 1000px) {
    
    height: calc(3.375rem*0.50);
    height: 100%;
}
/* overflow: hidden; */
`
export const BottomFront = styled.div`

height: calc(3.375rem*0.86);
display: flex;
    width: 100%;
    height: 100%;
${props => isSafari ? `
-webkit-perspective: 0;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0,0,0);
    visibility:visible;

` : `
overflow: hidden;

`}
/* position: absolute;
bottom: 0;
left: 0; */

@media(max-width: 1000px) {
    
    height: calc(3.375rem*0.50);
    height: 100%;
}
`

export const TopFront = styled.div`
backface-visibility: hidden;
height: calc(3.375rem*0.86);

${props => isSafari ? `
-webkit-perspective: 0;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0,0,0);
    visibility:visible;

` : `
overflow: hidden;

`}
/* position: absolute;
top: 0;
left: 0; */

@media(max-width: 1000px) {
    
    height: calc(3.375rem*0.50);
}
`


export const TopBack = styled.div`
backface-visibility: hidden;

${props => isSafari ? `
-webkit-perspective: 0;
    -webkit-backface-visibility: hidden;
    visibility:visible;
    -webkit-transform: translate3d(0,0,0);

` :``}
height: calc(3.375rem*0.86);
overflow: hidden;
transform: rotateY(180deg) rotateZ(180deg);
-webkit-transform: rotateY(180deg) rotateZ(180deg);
position: absolute;
display: flex;
align-items: flex-end;
top: 0;
left: 0;
z-index: 1;
@media(max-width: 1000px) {
    
    height: calc(3.375rem*0.50);
}
`