import { DescriptionText, DetailsButton, GamesContainer, GameContainer, GameSubtitle, GameTitle, MainSectionContainer, MainSectionWrapper, ImageContainer, OverlayContainer, VideosContainer, VideoWrapper, Divider, Contacts, GameInnerWrapper, VideosWrapper, VideoOuterWrapper, DotsContainer, PlaceHolderText, TitleWrapper } from "./main-section.styles"

import { ReactComponent as Game1SVG } from '../../assets/game1.svg'
import { ReactComponent as Game2SVG } from '../../assets/game2.svg'
import { useWindowDimensions } from "../../hooks/dimensions"
import { useTheme } from "styled-components"
import ReactPlayer from 'react-player'
import { ReactComponent as PlaySVG } from '../../assets/play.svg'
import { useEffect, useRef, useState } from "react"
import AvatarModal from "./AvatarModal/avatarModal"
import YourGame from "./AvatarModal/yourGame"
import { useInView } from "react-intersection-observer"
import { Routes } from "../Header/header"
import NavigationLines from "../NavigationLines/navigationLines"
import { scaleElementToWidth } from "../../utils/utils"
import { RequestButton } from "../Header/header.styles"

import Carousel, { slidesToShowPlugin, Dots } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

import MobileAvatar from '../../assets/mobile-avatar.png'
import { Dot, RedDot } from "./AvatarModal/avatarModal.styles"
import { NavigationSectionTitle } from "../NavigationSection/navigationSection.styles"

const MainSection = ({ sendRequest, onSelectSection }: { sendRequest: () => void, onSelectSection: (route: Routes) => void }) => {
    const { width } = useWindowDimensions()
    const theme = useTheme()
    const [showAvatarModal, setShowAvatarModal] = useState(false)
    const svgRef = useRef<any>(null)
    const [currentSlide, setCurrentSlide] = useState(0)


    const onSlideChange = (value: number) => {
        setCurrentSlide(value);
    }

    const toggleAvatarModal = () => {
        setShowAvatarModal(c => !c)
    }

    const [showGameModal, setShowGameModal] = useState(false)


    const toggleGameModal = () => {
        setShowGameModal(c => !c)
    }
    const { inView, ref } = useInView({
        threshold: 0.3
    })

    useEffect(() => {
        if (inView) {
            onSelectSection(Routes.GAME)
        }
    }, [inView])




    const calculateAngle = () => {

        const height = svgRef.current?.getBoundingClientRect().height ?? 0

        const sina = (height * 0.08 / Math.sqrt(width * width + height * 0.08 * height * 0.08))


        const cosa = Math.sqrt(1 - sina * sina)

        const tga = sina / cosa
        const x = tga * width


        return [x, height * 0.1]
    }





    return (
        <MainSectionWrapper id='Игры' ref={ref}>

            {
                width > 1000 &&
                <NavigationLines
                    selectedSection={Routes.GAME}
                    color="#fff"
                    top="35%"


                />
            }
            <MainSectionContainer>
                {
                    width > 1000 &&
                    <Contacts onClick={(e) => e.stopPropagation()}>
                        <a href='tel:79161830708' target={'_blank'} rel='noopener noreferrer'>
                            +7 916 183-07-08
                        </a>
                        <a href='mailto:ms@we-group.ru' target={'_blank'} rel='noopener noreferrer'>
                            ms@we-group.ru
                        </a>
                    </Contacts>
                }
                <DescriptionText>
                    Драйв, приключения, эмоции и настоящий тимбилдинг – и всё это онлайн!

                    {/* Инструмент, помогающий сотрудникам компаний адаптироваться к изменившимся <br /> и продолжающим меняться {width < 1000 && <br />} условиям работы. */}
                </DescriptionText>
                {
                    width < 1000 &&
                    <RequestButton onClick={sendRequest}>
                        ОТПРАВИТЬ ЗАПРОС
                    </RequestButton>
                }
                <GamesContainer>
                    <GameContainer>
                        <GameInnerWrapper>
                            <GameTitle>
                                Аватар
                            </GameTitle>
                            <GameSubtitle>
                                Командная стратегическая поисковая игра.<br /> Исследуйте город, управляя<br /> игротехником-Аватаром!
                                {/* Cтратегическая поисковая {width < 1000 && <br />} онлайн-игра<br /> с элементами IRL */}
                            </GameSubtitle>
                            <DetailsButton onClick={toggleAvatarModal}>
                                ПОДРОБНЕЕ
                            </DetailsButton>

                            <ImageContainer>
                                {
                                    width > 1000 ?
                                        <img src='/game1.png' alt='avatar' style={{
                                            width: '43.0625rem', height: '30.625rem'

                                        }} />
                                        // <Game1SVG width={'43.0625rem'} height={'30.625rem'} />
                                        :
                                        <img src={MobileAvatar} alt='avatar' />
                                }
                            </ImageContainer>

                        </GameInnerWrapper>
                        {
                            width > 1000 &&
                            <OverlayContainer>
                                <svg width={width / 2} height={scaleElementToWidth(width, 142)}  >
                                    <path d={`M 0 ${scaleElementToWidth(width, 43)} L 0 ${scaleElementToWidth(width, 142)} L ${width / 2} ${scaleElementToWidth(width, 142)} L ${width / 2} 0 L 0 ${scaleElementToWidth(width, 43)}`} fill={(theme as any).palette.colors.black} />
                                </svg>
                            </OverlayContainer>
                        }
                    </GameContainer>
                    <GameContainer>
                        <GameInnerWrapper>
                            <GameTitle>
                                Твоя Игра
                            </GameTitle>
                            <GameSubtitle style={width < 1000 ? {
                                textAlign: 'end'
                            } : undefined}>
                                Интеллектуально-{width < 1000 && <br />}творческая игра,<br /> которую каждая{width < 1000 && <br />} команда собирает для<br /> себя сама в режиме{width < 1000 && <br />} реального времени
                            </GameSubtitle>
                            <DetailsButton onClick={toggleGameModal}>
                                ПОДРОБНЕЕ
                            </DetailsButton>
                            <ImageContainer style={{
                                transform: width > 1000 ? 'translateY(-0.15rem)' : 'translateX(-1rem)'
                            }}>
                                {/* {
                                    width > 1000 ? 
                                    <img ref={svgRef} style={{
                                        width: width > 1000 ? '40.8125rem' : width * 1.2, height: width > 1000 ? '30.625rem' : width * 1.2 * 30.625 / 40.8125
                                    }} src={'/game2.png'} alt='game2' />
                                    :
                                } */}
                                <Game2SVG ref={svgRef} width={width > 1000 ? '40.8125rem' : width * 1.2} height={width > 1000 ? '30.625rem' : width * 1.2 * 30.625 / 40.8125} />
                            </ImageContainer>

                        </GameInnerWrapper>
                        <OverlayContainer>
                            <svg width={width > 1000 ? width / 2 : width} height={scaleElementToWidth(width, width > 1000 ? 142 : calculateAngle()[1] + ((width - 320) * 20 / 280) + 49)}  >
                                <path d={width > 1000 ? `M 0 ${scaleElementToWidth(width, width > 1000 ? 43 : 32)} L 0 ${scaleElementToWidth(width, width > 1000 ? 142 : 90)} L ${width / 2} ${scaleElementToWidth(width, width > 1000 ? 142 : 90)} L ${width / 2} 0 L 0 ${scaleElementToWidth(width, width > 1000 ? 43 : 32)}`
                                    : `M 0 ${scaleElementToWidth(width, width > 1000 ? 43 : calculateAngle()[0])} L 0 ${scaleElementToWidth(width, width > 1000 ? 142 : calculateAngle()[1] + ((width - 320) * 20 / 280) + 49)} L ${width} ${scaleElementToWidth(width, width > 1000 ? 142 : calculateAngle()[1] + ((width - 320) * 20 / 280) + 49)} L ${width} 0 L 0 ${scaleElementToWidth(width, width > 1000 ? 43 : calculateAngle()[0])}`
                                } fill={(theme as any).palette.colors.black} />



                            </svg>
                        </OverlayContainer>
                    </GameContainer>
                </GamesContainer>
                <TitleWrapper >
                    <NavigationSectionTitle >
                        <div />
                        <h5>
                            ВИДЕО

                        </h5>
                    </NavigationSectionTitle>

                </TitleWrapper>
                {
                    width > 1000 ?
                        <VideosContainer>
                            <VideoWrapper preview="url(avatar-placeholder.png)">
                                <ReactPlayer className='react-player' width='100%'
                                    height='100%' url='https://youtu.be/HBDt2fMX15A' controls light playIcon={<PlaySVG id='avatar-play' width={width > 1000 ? '10rem' : '4.125rem'} />} />

                            </VideoWrapper>
                            {
                                width > 1000 &&
                                <Divider style={{
                                    margin: '0 5.875rem'
                                }}>
                                    <div />
                                </Divider>
                            }
                            <VideoWrapper red>
                                {/* <ReactPlayer className='react-player' width='100%'
                                    height='100%' url='https://era-minio.lilekov-studio.com/mediakit/era_coin.mp4' controls light playIcon={<PlaySVG width={width > 1000 ? '10rem' : '4.125rem'} fill={(theme as any).palette.colors.black} />} /> */}
                                <PlaceHolderText>
                                    COMING SOON
                                </PlaceHolderText>
                            </VideoWrapper>

                        </VideosContainer>


                        :
                        <VideosContainer>
                            <VideosWrapper>
                                <Carousel

                                    value={currentSlide}
                                    onChange={onSlideChange}
                                    plugins={[

                                        'fastSwipe',
                                        {
                                            resolve: slidesToShowPlugin,
                                            options: {
                                                numberOfSlides: 1
                                            }
                                        },
                                    ]}
                                >
                                    <VideoOuterWrapper>
                                        <VideoWrapper preview="url(avatar-placeholder.png)">
                                            <ReactPlayer className='react-player' width='100%'
                                                height='100%' url='https://youtu.be/HBDt2fMX15A' controls light playIcon={<PlaySVG id='avatar-play' width={width > 1000 ? '10rem' : '4.125rem'} />} />

                                        </VideoWrapper>
                                    </VideoOuterWrapper>
                                    {
                                        width > 1000 &&
                                        <Divider style={{
                                            margin: '0 5.875rem'
                                        }}>
                                            <div />
                                        </Divider>
                                    }
                                    <VideoOuterWrapper>
                                        <VideoWrapper>
                                            {/* <ReactPlayer className='react-player' width='100%'
                                                height='100%' url='https://era-minio.lilekov-studio.com/mediakit/era_coin.mp4' controls light playIcon={<PlaySVG width={width > 1000 ? '10rem' : '4.125rem'} fill={(theme as any).palette.colors.black} />} /> */}
                                            <PlaceHolderText>
                                                COMING SOON
                                            </PlaceHolderText>
                                        </VideoWrapper>
                                    </VideoOuterWrapper>
                                </Carousel>
                            </VideosWrapper>
                        </VideosContainer>
                }
                {
                    width < 1000 &&
                    <DotsContainer>

                        <Dots
                            value={currentSlide}
                            onChange={onSlideChange}
                            thumbnails={[
                                <RedDot active={currentSlide === 0} />,
                                <RedDot active={currentSlide === 1} />,
                            ]}
                        />
                    </DotsContainer>
                }
            </MainSectionContainer>
            <AvatarModal
                sendRequest={sendRequest}
                open={showAvatarModal}
                onClose={toggleAvatarModal}
            />
            <YourGame
                sendRequest={sendRequest}
                open={showGameModal}
                onClose={toggleGameModal}
            />
        </MainSectionWrapper>
    )
}



export default MainSection