import { ReactElement, useEffect } from "react"
import { IconContainer, MechanicsBottom, MechanicsItemContainer, MechanicsLine, MechanicsRow, MechanicsSectionContainer, MechanicsSectionTitle, MechanicsSectionWrapper, MechanicsText } from "./mechanicsSection.styles"
import { ReactComponent as ArrowSVG } from '../../assets/arrow.svg'
import { ReactComponent as GraphSVG } from '../../assets/graph.svg'
import { ReactComponent as HandshakeSVG } from '../../assets/handshake.svg'
import { ReactComponent as LeaderSVG } from '../../assets/leader.svg'
import { ReactComponent as MonitorSVG } from '../../assets/monitor.svg'
import { ReactComponent as QuestionSVG } from '../../assets/question.svg'
import { ReactComponent as SplitSVG } from '../../assets/split.svg'
import { ReactComponent as StarsSVG } from '../../assets/stars.svg'
import { ReactComponent as TeamSVG } from '../../assets/team.svg'
import TeamPNG from '../../assets/team.png'
import { ReactComponent as TestSVG } from '../../assets/test.svg'
import { useInView } from "react-intersection-observer"
import { Routes } from "../Header/header"
import NavigationLines from "../NavigationLines/navigationLines"
import { useWindowDimensions } from "../../hooks/dimensions"

const Sections: {
    [key: number]: {
        icon: ReactElement;
        text: string;
    }
} = {
    0: {
        icon: <ArrowSVG />,
        text: 'Вводим логин (имя и фамилия участника) и уникальный пароль игры'
    },
    1: {
        icon: <img src={TeamPNG} alt='team'/>,
        text: 'Попадаем в конкретную игру  и в конкретную команду'
    },
    2: {
        icon: <QuestionSVG />,
        text: 'Читаем и обсуждаем с коллегами командные вводные'
    },
    3: {
        icon: <TestSVG />,
        text: 'Составляем командную стратегию игры и выполняем задания в выбранном порядке'
    },
    4: {
        icon: <MonitorSVG />,
        text: 'Смотрим и слушаем ведущего-модератора'
    },
    5: {
        icon: <LeaderSVG />,
        text: 'В этом поможет наш тимлидер-фасилитатор!'
    },
    6: {
        icon: <SplitSVG />,
        text: 'Развитие сюжета для каждой из команд зависит от принятых ей решений'
    },
    7: {
        icon: <HandshakeSVG />,
        text: 'Коммуницируем с персонажами игры и используем полученную от них информацию'
    },
    8: {
        icon: <GraphSVG />,
        text: 'Рейтинг команд в режиме реального времени и результаты - сразу по завершению игры'
    },
    9: {
        icon: <StarsSVG />,
        text: 'По желанию заказчика – модерируемое обсуждение итогов игры, рефлексия, перенос игрового опыта в реальную жизнь'
    },

}


const rows = [[0, 1, 2], [3, 4, 5], [6, 7, 8, 9]]






const MechanicsSection = ({ onSelectSection }: { onSelectSection: (route: Routes) => void }) => {
    const { width } = useWindowDimensions()
    const { inView, ref } = useInView({
        threshold: 0.3
    })

    useEffect(() => {
        if (inView) {
            onSelectSection(Routes.GAMEPLAY)
        }
    }, [inView])

    return (
        <MechanicsSectionWrapper id='Механика' ref={ref}>
            {
                width > 1000 &&
            <NavigationLines
                selectedSection={Routes.GAMEPLAY}
                color="#D5333E"
                top="50%"

                />
            }

            <MechanicsSectionContainer>
                <MechanicsSectionTitle>
                    {
                        width>1000 &&
                    <div />
                    }
                    <h5>

                        МЕХАНИКА ИГРЫ
                    </h5>
                    {
                        width<1001 &&
                    <div />
                    }
                </MechanicsSectionTitle>
                {
                    width > 1000 ? rows.map((row, index) => {
                        return (
                            <MechanicsRow key={index}>
                                {
                                    row.map((item) => {
                                        return (
                                            <MechanicsItemContainer key={item}>
                                                <MechanicsBottom id={`mechanicsCircle${item}`}>
                                                    <IconContainer>
                                                        {Sections[item].icon}
                                                    </IconContainer>
                                                </MechanicsBottom>
                                                <MechanicsLine />
                                                <MechanicsText>
                                                    {Sections[item].text}
                                                </MechanicsText>
                                            </MechanicsItemContainer>
                                        )
                                    })
                                }
                            </MechanicsRow>
                        )
                    })
                        :


                        rows.flat().map((item, index) => {
                    
                             return <MechanicsRow key={item} >
                                <MechanicsItemContainer style={{
                                
                                transform: (index === 0 || index === rows.flat().length-1) ? 'none' : index%2 === 0 ? 'translateX(4rem)' : 'translateX(-4rem)'
                             }}>
                                    <MechanicsBottom id={`mechanicsCircle${item}`}>
                                        <IconContainer>
                                            {Sections[item].icon}
                                        </IconContainer>
                                    </MechanicsBottom>
                                    <MechanicsLine />
                                    <MechanicsText style={{
                                left: '50%',
                                transform: (index === 0 || index === rows.flat().length-1) ? 'translateX(-50%)' : index%2 === 0 ? ' translateX(-50%) translateX(-4rem)' : ' translateX(-50%) translateX(4rem)'
                             }}>
                                        {Sections[item].text}
                                    </MechanicsText>
                                </MechanicsItemContainer>
                            </MechanicsRow>
                        })
                }
            </MechanicsSectionContainer>
        </MechanicsSectionWrapper>
    )
}





export default MechanicsSection