import { DefaultTheme } from "styled-components";

export const theme: DefaultTheme = {
    palette: {
        colors: {
            black: '#242738',
            red: '#D5333E',
            grey: '#E5E1EE',
            white: '#ffffff',
            lightGrey:'#E5E5E5',
            violet: '#91B3FA',
            lightBlack: '#474242',
        }
    }
}