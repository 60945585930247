import styled from 'styled-components'



export const AwardsSectionWrapper = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
width: 100%;
margin-bottom: 8rem;
margin-top: 8rem;
position: relative;


@media(max-width: 1000px) {
    margin-bottom: 2.5rem;
    margin-top: 3.5rem;
}
`



export const AwardsSectionContainer = styled.div`
width: 100%;
max-width: 120rem;
display: flex;
flex-direction: column;
.awards-slider {
    .slick-slide {
        &:not(:last-of-type) {
            margin-right: 5.75rem;
        }
    }
}
`



export const AwardsList = styled.div`
display: flex;
flex-direction: row;
align-items: center;
width: 100%;
justify-content: center;
>div {
    &:not(:last-of-type) {
        margin-right: 5.75rem;
    }
}


@media(max-width: 1000px) {
    .BrainhubCarousel__trackContainer,  .BrainhubCarousel, .BrainhubCarousel__container  {
    overflow: visible;
}
justify-content: flex-start;
>div {
    &:not(:last-of-type) {
        margin-right: 0rem;
    }
}
}
`


export const AwardListWrapper = styled.div`
width: 240px;
padding-left: 16px;
overflow: visible;
`



export const AwardsSectionTitle = styled.div`
    margin-right: 16%;
    display: flex;
    align-items: center;
    align-self: flex-end;
    margin-bottom: 9rem;
    text-align: end;
    >div {
        height: 2px;
        width: 4.675rem;
        border-radius: 0.625rem;
        align-self: flex-start;
        background: ${props => props.theme.palette.colors.red};
        position: relative;
        top: 2.4rem;
    }
    >h5 {
        margin-right: 1.5rem;
        color: ${props => props.theme.palette.colors.black};
        font-weight: bold;
        font-size: 4rem;
        line-height: 120%;
        text-transform: uppercase;
    }


    @media(max-width: 1000px) {
        margin-right: 0.25rem;
        margin-bottom: 3.5rem;
        >div {
            width: 0.5rem;
            top: 0.725rem;
        }
        >h5 {
            font-size: 1.25rem;
            margin-right: 0.25rem;
        }
    }
`



export const DotsContainer = styled.div`
width: 100%;
display: flex;
flex-direction: row;
justify-content: center;
margin-top: 1rem;

`