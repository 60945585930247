import { background, Modal, ModalContent, ModalOverlay } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { ArrowLeft, ArrowRight, ButtonsContainer, CarouselContainer, CarousleInnerWrapper, CarousleWrapper, CloseContainer, ContentContainer, DescriptionHightlight, DescriptionRedHightlight, DescriptionText, Dot, DownloadButton, Line, RequestButton, SectionContainer, SlideContainer, SVGContainer, TitleContainer } from "./avatarModal.styles"
import { ReactComponent as CloseSVG } from '../../../assets/cross.svg'
import { ReactComponent as ArrowSVG } from '../../../assets/arrow-right.svg'
import { ReactComponent as LocationSVG } from '../../../assets/location.svg'
import { ReactComponent as DownloadSVG } from '../../../assets/download.svg'
import { ReactComponent as PeopleSVG } from '../../../assets/people.svg'
import Carousel, { slidesToShowPlugin, Dots } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import SlideOne from '../../../assets/custom.png'
import { useWindowDimensions } from "../../../hooks/dimensions"
// import Slide2 from '../../../assets/slide2.png'
// import Slide3 from '../../../assets/slide3.png'
// import Slide4 from '../../../assets/slide4.png'
// import Slide5 from '../../../assets/slide5.png'
// import Slide6 from '../../../assets/slide6.png'
// import Slide7 from '../../../assets/slide7.png'
// import Slide8 from '../../../assets/slide8.png'






interface ModalProps {
    open: boolean,
    onClose: () => void,
    sendRequest: () => void
}




const YourGame: React.FC<ModalProps> = ({
    onClose,
    open,
    sendRequest
}) => {


    const {width} = useWindowDimensions()
    // useEffect(() => {
    //     const header = document.querySelector('header')
    //     if (header ) {
    //         if (open) {
    //         header.style.paddingRight='8px'   
    //         } else {
    //             header.style.paddingRight='0px'
    //         }

    //     }
    // }, [open])

    const [currentSlide, setCurrentSlide] = useState(0)



    const onSlideChange = (value: number) => {
        setCurrentSlide(value);
    }


    return (
        <Modal isOpen={open}  size={width< 1001 ? 'full': undefined} onClose={onClose} scrollBehavior="outside" blockScrollOnMount={false}>
            <ModalOverlay
                backdropFilter={'blur(1rem)'} background='#fafafa44' />
            <ModalContent background={'transparent'} width={'100%'} maxW={'61.25rem'} borderRadius={'3rem'}>
                <ContentContainer>
                    <CloseContainer onClick={onClose}>
                        <CloseSVG />

                    </CloseContainer>
                    <Line style={width<1000 ? {left: '2rem', height: '1.875rem', width: '2px'} : undefined}/>
                    <TitleContainer>
                        Твоя Игра
                    </TitleContainer>
                    <DescriptionText style={width< 1000 ? {padding: '0rem 1rem'} : undefined}>
                        Каждый уникален, а значит, каждому нужна <DescriptionHightlight>ЕГО</DescriptionHightlight> собственная игра.<br />
                        А мы (как удачно) знаем, как сделать такую игру <DescriptionHightlight>КОМАНДНОЙ.</DescriptionHightlight>


                    </DescriptionText>
                    <DescriptionText style={width< 1000 ? {padding: '0rem 1rem'} : undefined}>
                        <DescriptionRedHightlight> УДОБНО ПРОВОДИТЬ В ФОРМАТЕ МАРАФОНА</DescriptionRedHightlight> {' '} (От нескольких дней до месяца!)

                    </DescriptionText>
                    <SectionContainer>
                        <SVGContainer>
                            <LocationSVG />
                        </SVGContainer>
                        <DescriptionText>
                            <DescriptionHightlight>Огромный набор заданий:</DescriptionHightlight>{' '} каждый найдет что-то для себя лично.<br />
                            Можно выбирать <DescriptionHightlight>ЛЮБЫЕ</DescriptionHightlight> задания из списка.  Выполнять все – необязательно.

                        </DescriptionText>
                        <DescriptionText>
                            Интеллектуальные, творческие, поисковые, коммуникационные и даже физические задания. Набор заданий формируется вместе с Заказчиком.<br/>

                        </DescriptionText>
                    </SectionContainer>
                    <SectionContainer>
                        <SVGContainer>
                            <PeopleSVG />
                        </SVGContainer>
                        <DescriptionText>
                            <DescriptionHightlight>

                                Количество участников:
                            </DescriptionHightlight>
                            от
                            {' '}
                            <DescriptionRedHightlight>
                                10
                            </DescriptionRedHightlight>
                            {' '}
                            и до бесконечности
                        </DescriptionText>
                        <DescriptionText>
                            <DescriptionHightlight>

                                В команде:
                            </DescriptionHightlight>
                            {' '}
                            до
                            {' '}
                            <DescriptionRedHightlight>
                                10
                            </DescriptionRedHightlight>
                            {' '}
                            человек.
                        </DescriptionText>
                        <DescriptionText>
                            <DescriptionHightlight>

                                Продолжительность игры:
                            </DescriptionHightlight>
                            {' '}
                            от
                            {' '}
                            <DescriptionRedHightlight>
                                2
                            </DescriptionRedHightlight>
                            {' '}
                            часов и до бесконечности!
                        </DescriptionText>
                    </SectionContainer>
                    <CarousleWrapper>
                        {/* {
                            currentSlide>0 && <ArrowLeft onClick={ () => setCurrentSlide(c => c-1)}>
                                <ArrowSVG/>
                            </ArrowLeft>
                        }
                                            {
                            currentSlide<1 && <ArrowRight onClick={ () => setCurrentSlide(c => c+1)}>
                                <ArrowSVG/>
                            </ArrowRight>
                        } */}
                        <CarousleInnerWrapper>
                            <CarouselContainer style={width<1000 ? {width: '100%', justifyContent: 'center', padding: '0'} : undefined}>
                            <SlideContainer  style={width<1000 ? {display: 'flex', justifyContent: 'center', padding: '0'} : undefined}>
                                        <img src={SlideOne} />

                                    </SlideContainer>
                                {/* <Carousel

                                    value={currentSlide}
                                    onChange={onSlideChange}
                                    plugins={[

                                        'fastSwipe',
                                        {
                                            resolve: slidesToShowPlugin,
                                            options: {
                                                numberOfSlides: 1
                                            }
                                        },
                                    ]}
                                >
                                    <SlideContainer>
                                        <img src={SlideOne} />

                                    </SlideContainer>
                                    <SlideContainer>
                                        <img src={SlideOne} />

                                    </SlideContainer>
                                    
              
                                </Carousel> */}
                            </CarouselContainer>
                        </CarousleInnerWrapper>
                    </CarousleWrapper>
                    {/* <Dots
                        value={currentSlide}
                        onChange={onSlideChange}
                        thumbnails={[
                            <Dot active={currentSlide === 0} />,
                            <Dot active={currentSlide === 1} />,
                            // <Dot active={currentSlide === 2} />,
                            // <Dot active={currentSlide === 3} />,
                            // <Dot active={currentSlide === 4} />,
                            // <Dot active={currentSlide === 5} />,
                            // <Dot active={currentSlide === 6} />,
                            // <Dot active={currentSlide === 7} />,
                        ]}
                    /> */}
                    <ButtonsContainer>
                        <DownloadButton href='/OnlineTeambuilding_YourGame_021221.pdf' target={'_blank'} rel='noopener noreferrer'>
                            <DownloadSVG />
                            СКАЧАТЬ ПРЕЗЕНТАЦИЮ <br />
                            С ГОТОВЫМИ СЦЕНАРИЯМИ
                        </DownloadButton>
                        <RequestButton onClick={() => {
                            onClose()
                            sendRequest()
                        }}>
                            ОТПРАВИТЬ ЗАПРОС
                        </RequestButton>
                    </ButtonsContainer>
                </ContentContainer>
            </ModalContent>
        </Modal>
    )
}



export default YourGame