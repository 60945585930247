import { Routes } from "../Header/header";



import { ReactComponent as TGSVg } from '../../assets/tg.svg'
import { ReactComponent as ShareSVg } from '../../assets/share.svg'
import { ReactComponent as InstaSVg } from '../../assets/insta.svg'
import { AgencyRow, ContactsRow, FooterContainer, FooterWrapper, NavigationRow, SocialsRow } from "./footer.styles";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { useWindowDimensions } from "../../hooks/dimensions";

interface HeaderProps {

    selectedSection: Routes,
    onSelectSection: (route: Routes, navigate?: boolean) => void,
    showRequestModal: () => void
}




const Footer: React.FC<HeaderProps> = ({
    onSelectSection,
    selectedSection,
    showRequestModal
}) => {


    const {width} = useWindowDimensions()


    const {inView, ref} = useInView({
        threshold: 0.3
    })

    useEffect(() => {
        if (inView) {
            onSelectSection(Routes.CONTACTS)
        }
    }, [inView])

    return (
        <FooterWrapper id='Контакты' ref={ref}>
            <FooterContainer>
                <SocialsRow>
                    <a target='_blank' rel='noopener noreferrer' href='https://t.me/weareweagency'>
                        <TGSVg />

                    </a>
                    
                    {/* <a target='_blank' rel='noopener noreferrer'>
                        <ShareSVg />

                    </a> */}
                </SocialsRow>
                <ContactsRow>
                    <a href='tel:79161830708' target={'_blank'} rel='noopener noreferrer'>
                        +7 916 183-07-08
                    </a>
                    <a href='mailto:ms@we-group.ru' target={'_blank'} rel='noopener noreferrer'>
                        ms@we-group.ru
                    </a>
                </ContactsRow>
                {
                    width > 1000 ?
                    <>
                <AgencyRow>
                    © 2020 Агентство смелых решений "Мы" и
                    
                        Urban detective engine
                
                </AgencyRow>
                <NavigationRow>
                    {
                        (Object.keys(Routes) as Array<keyof typeof Routes>).map((key, index) => {
                        
                            return (<span onClick={() => onSelectSection(Routes[key] as Routes, true)} key={index}>
                                {Routes[key]}
                            </span>)

                        })
                    }
                </NavigationRow>
                    </>
                    :
                    <>
                <NavigationRow>
                    {
                        (Object.keys(Routes) as Array<keyof typeof Routes>).map((key, index) => {
                        
                            return (<span onClick={() => onSelectSection(Routes[key] as Routes, true)} key={index}>
                                {Routes[key]}
                            </span>)

                        })
                    }
                </NavigationRow>
                           <AgencyRow>
                    © 2020<br/> Агентство смелых решений "Мы" <br/>и
                    
                        Urban detective engine
                    
                </AgencyRow>
                    </>
                }
            </FooterContainer>
        </FooterWrapper>
    )

}




export default Footer
