import styled from 'styled-components'



export const LandingContainer = styled.div`
display: flex;
flex-direction: column;
position: relative;
background: #fafafa;
min-height: 100vh;
overflow-x: hidden;
`