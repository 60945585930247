import { AboutSectionContainer, AboutSectionDescription, AboutSectionDescriptionWrapper, AboutSectionRedOverlay, AboutSectionText, AboutSectionTitle, AboutSectionWrapper, CompanySection, InfoContainer, RequestButton } from "./aboutSection.styles"

import { ReactComponent as ManSVG } from '../../assets/man.svg'
import { ReactComponent as InfoSVG } from '../../assets/info.svg'
import { ReactComponent as UsSVG } from '../../assets/us.svg'
import { Routes } from "../Header/header"
import { useInView } from "react-intersection-observer"
import { useEffect } from "react"
import NavigationLines from "../NavigationLines/navigationLines"
import { scaleElementToWidth } from "../../utils/utils"
import { useWindowDimensions } from "../../hooks/dimensions"

const AboutSection = ({
    sendRequest,
    onSelectSection
}: {
    sendRequest: () => void,
    onSelectSection: (route: Routes) => void
}) => {

    const { width } = useWindowDimensions()

    const { inView, ref } = useInView({
        threshold: 0.3
    })

    useEffect(() => {
        if (inView) {
            onSelectSection(Routes.AUTHORS)
        }
    }, [inView])


    return (
        <>
            <AboutSectionWrapper id='aboutPage'>
                {
                    width > 1000 &&
                    <NavigationLines
                        selectedSection={Routes.AUTHORS}
                        color="#D5333E"
                        top="60%"


                    />
                }
                <AboutSectionContainer >
                    <AboutSectionDescriptionWrapper>
                        <AboutSectionRedOverlay />
                        <AboutSectionDescription>
                            <ManSVG width={width > 1000 ? scaleElementToWidth(width, width > 1400 ? 834 : 750) : width * 1.1} height={width > 1000 ? scaleElementToWidth(width, width > 1400 ? 833 : 750) : width * 1.1} />
                            <AboutSectionText>
                                <InfoSVG width={width > 1000 ? scaleElementToWidth(width, 114) : 114} height={width > 1000 ? scaleElementToWidth(width, 114) : 114} />
                                <div>
                                    <span>
                                        А ещё – можно решать не только HR-задачи, но и проводить игры для клиентов, партнёров и покупателей.
                                    </span>
                                    <span>
                                        У нас есть новый работающий инструмент – давайте вместе придумаем, как ещё его можно эффективно использовать.
                                    </span>
                                </div>
                            </AboutSectionText>
                        </AboutSectionDescription>

                    </AboutSectionDescriptionWrapper>
                    <InfoContainer ref={ref} >
                        <AboutSectionTitle >
                            <div id='Авторы'>
                                <div />
                                <h5 >
                                    АВТОРЫ{width > 1000 && <br />}{' '}
                                    ПРОЕКТА:
                                </h5>

                            </div>
                        </AboutSectionTitle>
                        <CompanySection>
                            <UsSVG width={scaleElementToWidth(width, 268)} height={scaleElementToWidth(width, 238)} />
                            <h6>
                                Агентство смелых решений «Мы»<br />
                                <a style={{cursor: 'pointer'}} href='http://we-agency.ru/' target={'_blank'} rel={'noopener noreferrer'}>
                                    we-agency.ru
                                </a>

                            </h6>
                            <span>
                                <strong>
                                    С 2000 года
                                </strong> {' '}
                                придумываем {width < 1000 && <br />} и проводим командообразующие{width > 1000 && <br />} программы
                            </span>
                            <span>
                                <strong>
                                    С 2010 года
                                </strong> {' '}
                                на рынке под брендом «Мы»

                            </span>
                            <span>
                                <strong>
                                    С 2015
                                </strong> {' '}
                                рассказываем на всех профессиональных площадках о том,<br />   что тимбилдинг – это
                                <span>
                                    #небегвмешках
                                </span>

                            </span>
                        </CompanySection>
                    </InfoContainer>
                </AboutSectionContainer>
            </AboutSectionWrapper>
            <RequestButton onClick={sendRequest}>
                ОТПРАВИТЬ ЗАПРОС
            </RequestButton>
        </>
    )
}



export default AboutSection