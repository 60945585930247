import { CirclesContainer, InfoCircle, InfoCircleHighlight, InfoCircleMobileHighlight, InfoCircleMobileText, InfoCircleText } from "../AdvantagesSection/advantagesSection.styles"

import { ReactComponent as LampSVG } from '../../assets/lamp.svg'

import { ReactComponent as StarsSVG } from '../../assets/stars2.svg'
import { ReactComponent as SchemeSVG } from '../../assets/scheme.svg'
import { InfoSectionContainer, InfoSectionWrapper } from "./InfoSection.styles"
import { scaleElementToWidth } from "../../utils/utils"
import { useWindowDimensions } from "../../hooks/dimensions"


const InfoSection = () => {

    const { width } = useWindowDimensions()

    return (
        <InfoSectionWrapper>
            <InfoSectionContainer>
                {
                    width > 1000 ? 
                    <CirclesContainer>
                        <InfoCircle radius="33.125rem" zIndex={1}>
                            <StarsSVG width={scaleElementToWidth(width, 181)} height={scaleElementToWidth(width, 164)} />
                            <InfoCircleText
                                style={{
                                    maxWidth: '22rem'
                                }}
                            >

                                Разрабатываем игры, которые рассказывают истории.                          Интересные и захватывающие.
                            </InfoCircleText>

                        </InfoCircle>
                        <div style={{
                            width: '18rem'
                        }}></div>
                        <InfoCircle radius="33.125rem" zIndex={1}>
                            <LampSVG width={scaleElementToWidth(width, 188)} height={scaleElementToWidth(width, 146)} />
                            <InfoCircleText
                                style={{
                                    maxWidth: '22rem'
                                }}
                            >
                                Продаем свой мозг и экспертизу. Разрабатываем новые игровые решения для бизнеса.<br /> <strong>Ежедневно.</strong>


                            </InfoCircleText>

                        </InfoCircle>
                        <div style={{
                            zIndex: 2,
                            bottom: '15rem'
                        }}>
                            <InfoCircle

                                radius="33.125rem" zIndex={2}>
                                <SchemeSVG width={scaleElementToWidth(width, 176)} height={scaleElementToWidth(width, 165)} />
                                <InfoCircleText
                                    style={{
                                        maxWidth: '22rem'
                                    }}
                                >
                                    Трансформируем любые задачи заказчика в игровой тренинг.


                                </InfoCircleText>

                            </InfoCircle>
                        </div>
                    </CirclesContainer>
                    :
                    <CirclesContainer>
                    <InfoCircle radius={`${width * 0.75}px`} zIndex={2}>
                        <SchemeSVG width={'5.375rem'} height={'5rem'} />
                        <InfoCircleMobileText style={{
                            maxWidth: '70%'
                        }}>
                            Трансформируем любые задачи заказчика в игровой тренинг.
                        </InfoCircleMobileText>

                    </InfoCircle>
                    <div style={{
                        height: '8rem'
                    }}></div>
                    <InfoCircle radius={`${width * 0.76}px`} zIndex={0} >
                        <LampSVG width={'5.75rem'} height={'4.5rem'} />
                        <InfoCircleText style={{
                            maxWidth: '70%'
                        }}>
                        Продаем свой мозг и экспертизу. Разрабатываем новые игровые решения для бизнеса.<br /> <strong>Ежедневно.</strong>

                        </InfoCircleText>

                    </InfoCircle>
                    <div style={{
                        zIndex: 1
                    }}>
                        <InfoCircle radius={`${width * 0.75}px`} zIndex={1} >
                            <StarsSVG width={'5.5rem'} height={'5rem'} />
                            <InfoCircleText style={{
                            maxWidth: '70%'
                        }}>

                            Разрабатываем игры, которые рассказывают истории.                          Интересные и захватывающие.
                            </InfoCircleText>

                        </InfoCircle>

                    </div>
                </CirclesContainer>
                }
            </InfoSectionContainer>
        </InfoSectionWrapper>
    )
}



export default InfoSection