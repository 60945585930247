import { Modal, ModalContent, ModalOverlay, Spinner } from "@chakra-ui/react"
import { CloseContainer, ContentContainer, Line } from "../MainSection/AvatarModal/avatarModal.styles"
import { ReactComponent as CloseSVg } from '../../assets/cross.svg'
import { useWindowDimensions } from "../../hooks/dimensions"
import { FormContainer, FormSubtitle, FormTitle, LoaderContainer, RequestButton, TextArea, Textinput } from "./requestModal.styles"
import { useState } from "react"

import * as yup from 'yup'
import { Formik } from "formik"
import { useCustomToast } from "../../hooks/toast"
import axios from "axios"
import { text } from "node:stream/consumers"
import { EMAIL_URL } from "../../config"


const validationSchema = yup.object({
    email: yup.string().required('Обязательное поле'),
    name: yup.string().required('Обязательное поле'),
    text: yup.string().required('Обязательное поле'),
})


interface ModalProps {
    open: boolean,
    onClose: () => void
}




const RequestModal: React.FC<ModalProps> = ({
    onClose,
    open
}) => {
    const [loading, setLoading] = useState(false)
    const { height , width} = useWindowDimensions()
    const toast = useCustomToast()

    const handleFormSubmit= async (values: {
        email: string,
        name: string,
        text: string
    }) => {

        if (loading) return
        setLoading(true)
        try {

            const repsonse = await axios.post(EMAIL_URL+'/mail', {
                text: values.text,
                name: values.name,
                phone: values.email
            })
            toast('', 'success', 'Запрос успешно отправлен')

            setLoading(false)

            console.log(repsonse)
            onClose()
        } catch (err) {
            setLoading(false)
            toast('', 'error', 'Не удалось отправить запрос')
        }

    
    }

    return (
        <Modal isOpen={open} onClose={onClose} isCentered={height > 900} scrollBehavior="outside" blockScrollOnMount={false} size={width<1001 ? 'full' : undefined}>
            <ModalOverlay
                backdropFilter={'blur(1rem)'} background='#fafafa44' />
            <ModalContent background={'transparent'} width={'100%'} maxW={'61.25rem'} borderRadius={width> 1000 ?'3rem': undefined} >
                <ContentContainer style={{
                    padding: width > 1000 ?'5.75rem 5.75rem 3rem 5.75rem' : '5.75rem 1.25rem 3rem 1.25rem'
                }}>
                    <CloseContainer>
                        <CloseSVg onClick={onClose}/>
                    </CloseContainer>
                    <Line
                        style={{
                            left: '50%'
                        }}
                    />
                    <FormTitle>
                        Проконсультируем <br />
                        и ответим на все вопросы.
                    </FormTitle>
                    <FormSubtitle>
                        Здесь напишите, что Вы хотите, и количество участников:
                    </FormSubtitle>
                    <Formik 
                    validationSchema={validationSchema}
                    initialValues={{
                        email: '',
                        name: '',
                        text: ''
                    }}
                    onSubmit={handleFormSubmit}
                    >
                        {
                            ({handleSubmit, handleChange, values, errors}) => {

                             return <FormContainer onSubmit={(e) => {
                                handleSubmit(e)
                             }}>
                                <TextArea
                                value={values.text}
                                onChange={handleChange}
                                name='text'
                                
                                error={errors.text ? true : false}
                                ></TextArea>
                                <Textinput
                                    placeholder="ВАШЕ ИМЯ"
                                    name='name'
                                    value={values.name}
                                    error={errors.name ? true : false}
                                onChange={handleChange}
                                />
                                <Textinput
                                name="email"
                                value={values.email}
                                error={errors.email ? true : false}
                                onChange={handleChange}
                                    placeholder="Ваш E-mail или телефон"
                                />
                                <RequestButton type='submit'>
                                    {
                                        loading &&
                                        <LoaderContainer color="white">
                                            <Spinner />
                                        </LoaderContainer>
                                    }
                                    ОТПРАВИТЬ ЗАПРОС
                                </RequestButton>
                            </FormContainer>
                            }
                        }
                    </Formik>
                </ContentContainer>
            </ModalContent>
        </Modal>
    )
}




export default RequestModal