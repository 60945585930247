import styled from 'styled-components'




export const HeaderWrapeer = styled.header`
width: 100%;
position: fixed;
top: 0;
left: 0;
z-index: 1000;
display: flex;
  flex-direction: row;
  justify-content: center;
  background: ${props => `${props.theme.palette.colors.red}AA`};
  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    backdrop-filter: blur(1rem);
  }

  @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    background: rgba(0, 0, 0, 0.1) !important;
  }
`



export const HeaderContainer = styled.div`
width: 100%;
max-width: 120rem;
padding: 3.125em 3.125rem 1rem 5.75rem;
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;

@media(max-width: 1000px) {
    padding: 1rem 1rem 0.5rem 1rem;
}
`


export const LogoContainer = styled.div`
>svg {
    width: 28.625rem;

}
@media(max-width: 1350px) {
    >svg {
        width: 15rem;
        height: 2rem;
    }
}

@media(max-width: 1000px) {
    >svg {
        width: 12.375rem;
        height: 1.75rem;
    }
}
`

export const NavigationContainer = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
`



export const NavigationOptionsContainer = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
margin-right: 4rem;
`

interface NavigationOptionProps {
    selected: boolean
}

export const NavigationOption = styled.span<NavigationOptionProps>`

    &:not(:last-of-type) {
        margin-right: 2.5rem;
    }

    font-size: 0.875rem;
    font-weight: 700;
    color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    >span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50% -50%);
        -webkit-transform: translate(-50%, -50%);
        font-weight: ${props => props.selected ? 700 : 400};
    color: ${props => props.theme.palette.colors.white};
    }
    position: relative;
    cursor: pointer;
    ${props => props.selected ? `
    &:after {
        content: '';
        position: absolute;
        left: 50%;
        width: 1px;
        height: 0.6rem;
        background: ${props.theme.palette.colors.white};
        bottom: -0.975rem;
    }
    
    
    ` : ``}

`



export const RequestButton = styled.button`
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
width: 13.4375rem;
height: 3.4375rem;
background: ${props => props.theme.palette.colors.black};
cursor: pointer;
color: ${props => props.theme.palette.colors.white};
font-weight: 600;
font-size: 0.875rem;
line-height: 120%;
/* identical to box height */
border-radius: 2rem;
text-transform: uppercase;


position: relative;

> div {
    position: absolute;
    bottom: -5.125rem;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    >a {
        display: flex;
        text-transform: lowercase;
        font-size: 1.25rem;
        text-align: right;
        line-height: 140%;

    }
}
`




export const MobileHeaderContainer = styled.div`
display: flex;
flex-direction: column;
width: 100%;
min-height: 100vh;
justify-content: space-between;
`



export const MobileContactsContainer = styled.div`
width: 100%;
background: ${props => props.theme.palette.colors.black};
display: flex;
min-height: 15rem;
flex: 1;
flex-direction: column;
padding: 1.5rem 1rem 2rem 1rem;
align-items: center;
justify-content: center;
`

export const SocialsRow = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
>a {
    cursor: pointer;
    &:not(:last-of-type) {
        
        margin-right: 2.25rem;
        }
}
margin-bottom: 1.5rem;
`

export const RedRequestButton = styled.button`
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
width: 13.4375rem;
height: 3.4375rem;
background: ${props => props.theme.palette.colors.red};
cursor: pointer;
color: ${props => props.theme.palette.colors.white};
font-weight: 600;
font-size: 0.875rem;
line-height: 120%;
/* identical to box height */
border-radius: 2rem;
text-transform: uppercase;


position: relative;

`



export const ContactLink = styled.a`
cursor: pointer;
color: ${props => props.theme.palette.colors.white};
font-size: 1rem;
font-weight: 600;
margin-bottom: 1.5rem;
`





export const MobileHeaderMenu = styled.div`

background: ${props => props.theme.palette.colors.white};
padding: 1.5rem 1rem;

display: flex;
flex-direction: column;
align-items: center;
width: 100%;
`



export const MobileMenuLogo = styled.div`
width: 100%;
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;

`

export const CrossContainer = styled.div`
cursor: pointer;
`



export const MobileNavigationContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
margin-top: 1.5rem;
`



export const MobileNavigationOption = styled.div`
cursor: pointer;
font-size: 1.25rem;
font-weight: 500;
color: ${props => props.theme.palette.colors.black};
line-height: 120%;
&:not(:last-of-type) {
    margin-bottom: 1rem;
}
`





