import { isSafari } from 'react-device-detect'
import styled from 'styled-components'


export const MechanicsSectionWrapper = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
width: 100%;
margin-top: 10.75rem;
margin-bottom: 18rem;
position: relative;
@media(max-width: 1000px) {
margin-top: 4rem;
margin-bottom: 12rem;
    }
`



export const MechanicsSectionContainer = styled.div`
width: 100%;
max-width: 120rem;
display: flex;
flex-direction: column;
`


export const MechanicsSectionTitle = styled.div`
    margin-left: 16%;
    display: flex;
    align-items: center;
    align-self: flex-start;
    margin-bottom: 7rem;
    >div {
        height: 2px;
        width: 4.675rem;
        border-radius: 0.625rem;
        background: ${props => props.theme.palette.colors.red};
    }
    >h5 {
        margin-left: 1.5rem;
        color: ${props => props.theme.palette.colors.black};
        font-weight: bold;
        font-size: 4rem;
    }
    @media(max-width: 1000px) {
        margin-left: 0rem;
        margin-right: 0.25rem;
        align-self: flex-end;
        margin-bottom: 1.75rem;
        >div {
            width: 0.5rem;
        }
        >h5 {
            font-size: 1.25rem;
            margin-left: 0.25rem;
            margin-left: 0rem;
        margin-right: 0.25rem;
        }
    }
`


export const MechanicsText = styled.span`
text-align: center;
font-size: 1rem;
font-weight: 600;
color: ${props => props.theme.palette.colors.black};
max-width: 18rem;
position: absolute;
width: max-content;
top: 12.625rem;

@media(max-width: 1000px) {
    
    top: 5.025rem;
    align-self: flex-start;
    font-size: 0.875rem;
    max-width: 13rem;
}
`


export const MechanicsRow = styled.div`
display: flex;
flex-direction: row;
align-items: flex-start;
width: 100%;
justify-content: center;
&:not(:last-of-type) {
    margin-bottom: 15rem;
}


@media(max-width: 1000px) {
    
    &:not(:last-of-type) {
    margin-bottom: 8rem;
}

}
`


export const MechanicsItemContainer = styled.div`
display: flex;
position: relative;
&:not(:last-of-type) {
    margin-right: 14rem;
}
flex-direction: column;
align-items: center;


@media(max-width: 1000px) {
    margin-right: 0;
}
`


export const MechanicsBottom = styled.div`
background: ${props => props.theme.palette.colors.red};
border-radius: 50%;
width: 7.375rem;
height: 7.375rem;
position: relative;
z-index: 1;


@media(max-width: 1000px) {
    width: 2.9rem;
height: 2.9rem;
}
`

export const MechanicsLine = styled.div`
width: 2px;
height: 4.25rem;
margin-bottom: 1rem;
border-radius: 0.25rem;
background: ${props => props.theme.palette.colors.red};


@media(max-width: 1000px) {
    height: 1.625rem;
}
`


export const IconContainer = styled.div`
width: 9.375rem;
height: 9.375rem;
position: absolute;
left: -3rem;
border-radius: 50%;
top: -2rem;
display:flex;
flex-direction: row;
align-items: center;
justify-content: center;
background: linear-gradient(320.4deg, rgba(255, 255, 255, 0.8) 8.13%, rgba(255, 255, 255, 0.3) 93.42%);
box-shadow: 0px 16.5318px 38.1503px rgba(137, 33, 33, 0.3);

${props => isSafari ? `
backdrop-filter: blur(0);
overflow: hidden;
::after {
    position: absolute;
    z-index: -1;
     content: '';
     top:0;
     left: 0;
     width: 100%;
     height: 100%;
     borderRadius: 50%;
     overflow: hidden;
     backdrop-filter: blur(10.1734px);

}
` : 'backdrop-filter: blur(10.1734px);'}

z-index: 2;
>svg {
    max-width: 60%;
}
>img {
    max-width: 60%;
}

@media(max-width: 1000px) {
    width: 3.625rem;
    height: 3.625rem;
    top: -0.725rem;
    left: -1rem;
}
`